import React from "react";
import styled from 'styled-components';
import Header from './components/Header'
import logo from './images/ADALogoBG.png';
import CardanoBanner from './images/only-on-cardano02.png';
// import ModelViewer from './components/ModelViewer';
import AboutProject from './components/AboutProject';
import PFPCarousel1 from './components/PFPCarousel1';
import PFPCarousel2 from './components/PFPCarousel2';
import PFPCarousel3 from './components/PFPCarousel3';
import PFPCarouselTest2 from './components/PFPCarouselTest2';

import Skymap from './components/Skymap';
import AboutTeam from './components/AboutTeam';
import AboutArtists from './components/AboutArtists';
import AboutDevs from './components/AboutDevs';
import FAQ from './components/FAQ';
import Footer from './components/Footer'
import CarouselContainer from "./components/Carousel/CarouselContainer";
// import background from './images/site-bg.png';




const StyledDiv = styled.div`
  -webkit-tap-highlight-color: transparent;
  
  * {
    /* border: 1px dotted orange; */
  }
  #App{
    /* max-height: 100%; */
    /* position: fixed; */
    
  }
  

  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: black; */

  #header{
    display: inline-block;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    position: -webkit-sticky;
    position: sticky;

    top: 0;
    /* bottom: 95; */
    /* left: 0px; */
    /* margin: 0; */
    z-index: 1000;
    background-color: lightcoral;
    /* height: 50px; */
  }

  #footer{
    height: 100%;
    margin: 0%;
    /* position: relative; */
    bottom: 0px;
  }

  .blazing {
    margin: 20% 3% 0% 3%;
    letter-spacing: 3px;
    font-family: 'New Rocker', cursive;
    color: whitesmoke;
    font-size: 2rem;
    text-align: center;
    line-height: 80px;
    outline: none;
    vertical-align: middle;
    
    text-shadow:
        0 3px 20px red,
        0 0 20px red,
        0 0 10px orange,
        4px -5px 6px yellow,
        -4px -10px 10px yellow,
        0 -10px 30px yellow;
    animation: 2s Blazing infinite alternate linear;
  }

  .title-logo{

    .title{
      width: 100%;
      height: 100%;
      h2{
        /* position: absolute; */
        font-family: 'New Rocker', cursive;
        padding: 1%;
        letter-spacing: 3px;
        font-size: 2rem;
        text-align: center;
        font-weight: bold;
        background-image: linear-gradient(
          to right,
          #462523 0,
          #cb9b51 22%, 
          #f6e27a 45%,
          #f6f2c0 50%,
          #f6e27a 55%,
          #cb9b51 78%,
          #462523 100%
        );
        color:transparent;
        -webkit-background-clip: text;
        background-clip: text;
        margin: 10% 1% 0% 1%;
    
        -webkit-text-stroke-width: 0.1px;
        -webkit-text-stroke-color: #1a1a00;
    
      }
    
      .hide{
        /* position: relative; */
        transform: translateY(0);
        opacity: 1;
        transition: .5s all ease;
        
      }
      .hide.active{
          transform: translateY(100px);
          opacity: 0;
      }
    
    }
    .app-bg{
      display: flex;
      justify-content: center;
      
      img{
        position:fixed;
        top: 35%;
        bottom: 65%;
        max-width: 70%;
        height: auto;
        opacity:.7;  
        animation: flickerAnimation 5s infinite;
        margin: 0% 30 0% 30%;
        padding: 1%;
        z-index: -1;
      }
    }
    .flexbox1{
      display: flex;
      flex-direction: column;
    }
  }
  .banner-wrapper{
    margin: 100% 10% 35% 10%;
    max-width: 100%;
    width: 80%;
    /* height: 20vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: lightcoral; */
    .cardano-banner{
      width: 100%;
      height: 20%;
      border-radius: 20px;
      animation: 2s Glowing-cardano infinite alternate linear;
      /* background-color: transparent; */
      /* box-shadow:
        0 3px 20px red,
        0 0 20px red,
        0 0 10px orange,
        4px -5px 6px yellow,
        -4px -10px 10px yellow,
        0 -10px 30px yellow;
      animation: 2s Blazing-Box infinite alternate linear; */
      
      .bg-wrapper{
        border-radius: 20px;
        animation: 3s Glowing-cardano-inner infinite alternate linear;

        /* box-shadow:
          inset 0 3px 20px red,
          inset 0 0 20px red,
          inset 0 0 10px orange,
          inset 4px -5px 6px yellow,
          inset -4px -10px 10px yellow,
          inset 0 -10px 30px yellow;
        animation: 2s Blazing-Box-Inner infinite alternate linear; */

        /* animation: 2s Blazing-Box infinite alternate linear; */
        /* box-shadow: 
          inset 0 43px 60px red,
          inset 0 0 60px red,
          inset 0 0 50px orange,
          inset 4px -45px 46px yellow,
          inset -4px -40px 40px yellow,
          inset 0 -40px 70px yellow;
        animation: 2s Blazing-Box-Inner infinite alternate linear; */
        img{
          width: 100%;
          height: auto;
          border-radius: 20px;

          /* opacity: .1; */
          /* background-color: transparent; */
        }
  
      }
    }
  }
  
  .gold-line{
        width: 100%;
        height: 1vh;
        margin: 2% 0 5% 0;
        background-image: linear-gradient(
          to right,
          #462523 0,
          #cb9b51 22%, 
          #f6e27a 45%,
          #f6f2c0 50%,
          #f6e27a 55%,
          #cb9b51 78%,
          #462523 100%
        );
    }
  /* .app-bg::after {
    content: "";
    background: url({logo});
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;   
  }

  #App::after {
    content: "";
    background: url({logo});
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;   
  } */

  @keyframes flickerAnimation {
    0%    { opacity:.4; }
    10%   { opacity:.5; }
    20%   { opacity:.6; }
    30%   { opacity:.4; }
    40%   { opacity:.5; }
    50%   { opacity:.6; }
    60%   { opacity:.4; }
    70%   { opacity:.5; }
    80%   { opacity:.6; }
    90%   { opacity:.4; }
    100%  { opacity:.5; }
  }
  @keyframes Blazing-Box {
    0%   { box-shadow: 
      0 3px 20px red, 
      0 0 20px red,
      0 0 10px orange,
      0 0 0 yellow,
      0 0 5px yellow,
      -2px -5px 5px yellow,
      4px -10px 10px yellow; 
    }
    25%   { box-shadow: 
      0 3px 20px red, 
      0 0 30px red,
      0 0 20px orange,
      0 0 5px yellow,
      -2px -5px 5px yellow,
      3px -10px 10px yellow,
      -4px -15px 20px yellow; 
    }
    50%   { box-shadow: 0 3px 20px red, 0 0 20px red,
      0 -5px 10px orange,
      -2px -5px 5px yellow,
      3px -10px 10px yellow,
      -4px -15px 20px yellow,
      2px -20px 30px rgba(255,255,0,0.5); 
    }
    75%   { box-shadow: 
      0 3px 20px red, 
      0 0 20px red,
      0 -5px 10px orange,
      3px -5px 5px yellow,
      -4px -10px 10px yellow,
      2px -20px 30px rgba(255,255,0,0.5),
      0px -25px 40px rgba(255,255,0,0)
    }
    100%   { box-shadow: 
      0 3px 20px red, 
      0 0 20px red,
      0 0 10px orange,
      0 0 0 yellow,
      0 0 5px yellow,
      -2px -5px 5px yellow,
      4px -10px 10px yellow; 
    }
  }
  @keyframes Blazing-Box-Inner {
    0%   { box-shadow: 
      inset 0 3px 20px red, 
      inset 0 0 20px red,
      inset 0 0 10px orange,
      inset 0 0 0 yellow,
      inset 0 0 5px yellow,
      inset -2px -5px 5px yellow,
      inset 4px -10px 10px yellow; 
    } 
    25%   { box-shadow: 
      inset 0 3px 20px red, 
      inset 0 0 30px red,
      inset 0 0 20px orange,
      inset 0 0 5px yellow,
      inset -2px -5px 5px yellow,
      inset 3px -10px 10px yellow,
      inset -4px -15px 20px yellow; 
    } 
    50%   { box-shadow: 
      inset 0 3px 20px red, 
      inset 0 0 20px red,
      inset 0 -5px 10px orange,
      inset -2px -5px 5px yellow,
      inset 3px -10px 10px yellow,
      inset -4px -15px 20px yellow,
      inset 2px -20px 30px rgba(255,255,0,0.5); 
    } 
    75%   { box-shadow: 
      inset 0 3px 20px red, 
      inset 0 0 20px red,
      inset 0 -5px 10px orange,
      inset 3px -5px 5px yellow,
      inset -4px -10px 10px yellow,
      inset 2px -20px 30px rgba(255,255,0,0.5),
      inset 0px -25px 40px rgba(255,255,0,0)
    } 
    100%   { box-shadow: 
      inset 0 3px 20px red, 
      inset 0 0 20px red,
      inset 0 0 10px orange,
      inset 0 0 0 yellow,
      inset 0 0 5px yellow,
      inset -2px -5px 5px yellow,
      inset 4px -10px 10px yellow; 
    }
  }
  /* @keyframes Blazing-Box-Inner {
    0%   { box-shadow: 
      inset 0 3px 40px red, 
      inset 0 0 40px red,
      inset 0 0 20px orange,
      inset 0 0 0 yellow,
      inset 0 0 55px yellow,
      inset -6px -5px 55px yellow,
      inset 10px -15px 60px yellow; 
    } 
    25%   { box-shadow: 
      inset 0 3px 40px red, 
      inset 0 0 50px red,
      inset 0 0 30px orange,
      inset 0 0 35px yellow,
      inset -6px -5px 45px yellow,
      inset 7px -10px 60px yellow,
      inset -8px -20px 70px yellow; 
    } 
    50%   { box-shadow: 
      inset 0 3px 40px red, 
      inset 0 0 40px red,
      inset 0 -5px 20px orange,
      inset -6px -5px 55px yellow,
      inset 7px -10px 60px yellow,
      inset -8px -15px 70px yellow,
      inset 6px -25px 80px rgba(255,255,0,0.5); 
    } 
    75%   { box-shadow: 
      inset 0 3px 40px red, 
      inset 0 0 40px red,
      inset 0 -5px 20px orange,
      inset 7px -5px 55px yellow,
      inset -8px -10px 60px yellow,
      inset 6px -20px 80px rgba(255,255,0,0.5),
      inset 0px -30px 90px rgba(255,255,0,0)
    }
    100%   { box-shadow: 
      inset 0 3px 30px red, 
      inset 0 0 30px red,
      inset 0 0 10px orange,
      inset 0 0 0 yellow,
      inset 0 0 45px yellow,
      inset -6px -5px 45px yellow,
      inset 8px -15px 50px yellow; 
    }
  } */
  @keyframes Blazing {
    0%   { text-shadow: 
      0 3px 20px red, 
      0 0 20px red,
      0 0 10px orange,
      0 0 0 yellow,
      0 0 5px yellow,
      -2px -5px 5px yellow,
      4px -10px 10px yellow; 
    }
    25%   { text-shadow: 
      0 3px 20px red, 
      0 0 30px red,
      0 0 20px orange,
      0 0 5px yellow,
      -2px -5px 5px yellow,
      3px -10px 10px yellow,
      -4px -15px 20px yellow; 
    }
    50%   { text-shadow: 0 3px 20px red, 0 0 20px red,
      0 -5px 10px orange,
      -2px -5px 5px yellow,
      3px -10px 10px yellow,
      -4px -15px 20px yellow,
      2px -20px 30px rgba(255,255,0,0.5); 
    }
    75%   { text-shadow: 
      0 3px 20px red, 
      0 0 20px red,
      0 -5px 10px orange,
      3px -5px 5px yellow,
      -4px -10px 10px yellow,
      2px -20px 30px rgba(255,255,0,0.5),
      0px -25px 40px rgba(255,255,0,0)
    }
    100%   { text-shadow: 
      0 3px 20px red, 
      0 0 20px red,
      0 0 10px orange,
      0 0 0 yellow,
      0 0 5px yellow,
      -2px -5px 5px yellow,
      4px -10px 10px yellow; 
    }
  }
  @keyframes Glowing-cardano{
    0% {
      -webkit-box-shadow:0px 0px 25px 12px #0033ac;
      -moz-box-shadow: 0px 0px 25px 12px #0033ac;
      box-shadow: 0px 0px 25px 12px #0033ac;
    }
    25% {
      -webkit-box-shadow:0px 0px 25px 6px #0033ac;
      -moz-box-shadow: 0px 0px 25px 6px #0033ac;
      box-shadow: 0px 0px 25px 6px #0033ac;
    }
    40% {
      -webkit-box-shadow:0px 0px 25px 4px #0033ac;
      -moz-box-shadow: 0px 0px 25px 4px #0033ac;
      box-shadow: 0px 0px 25px 4px #0033ac;
    }
    60% {
      -webkit-box-shadow:0px 0px 25px 2px #0033ac;
      -moz-box-shadow: 0px 0px 25px 2px #0033ac;
      box-shadow: 0px 0px 25px 2px #0033ac;
    }
    80% {
      -webkit-box-shadow:0px 0px 25px 4px #0033ac;
      -moz-box-shadow: 0px 0px 25px 4px #0033ac;
      box-shadow: 0px 0px 25px 4px #0033ac;
    }
    100% {
      -webkit-box-shadow:0px 0px 40px 6px #0033ac;
      -moz-box-shadow: 0px 0px 40px 6px #0033ac;
      box-shadow: 0px 0px 40px 6px #0033ac;
    }
  }
  @keyframes Glowing-cardano-inner{
    0% {
      -webkit-box-shadow: inset 0px 0px 25px 16px #0033ac;
      -moz-box-shadow: inset 0px 0px 25px 16px #0033ac;
      box-shadow: inset 0px 0px 25px 16px #0033ac;
    }
    25% {
      -webkit-box-shadow: inset 0px 0px 25px 10px #0033ac;
      -moz-box-shadow: inset 0px 0px 25px 10px #0033ac;
      box-shadow: inset 0px 0px 25px 10px #0033ac;
    }
    40% {
      -webkit-box-shadow: inset 0px 0px 25px 8px #0033ac;
      -moz-box-shadow: inset 0px 0px 25px 8px #0033ac;
      box-shadow: inset 0px 0px 25px 8px #0033ac;
    }
    60% {
      -webkit-box-shadow: inset 0px 0px 25px 6px #0033ac;
      -moz-box-shadow: inset 0px 0px 25px 6px #0033ac;
      box-shadow: inset 0px 0px 25px 6px #0033ac;
    }
    80% {
      -webkit-box-shadow: inset 0px 0px 25px 8px #0033ac;
      -moz-box-shadow: inset 0px 0px 25px 8px #0033ac;
      box-shadow: inset 0px 0px 25px 8px #0033ac;
    }
    100% {
      -webkit-box-shadow: inset 0px 0px 40px 10px #0033ac;
      -moz-box-shadow: inset 0px 0px 40px 10px #0033ac;
      box-shadow: inset 0px 0px 40px 10px #0033ac;
    }
  }
  @media (min-width: 400px) {
    .blazing{
      font-size: 2.5rem;
    }
    .title-logo{

      .title{

        h2 {
          font-size: 3rem;
          margin: 5% 1% 0% 1%;
        }
      }
      .app-bg{
        img{
          top: 25%;
          bottom: 75%;
        }
      }
    }
    .banner-wrapper{
      margin: 80% 10% 15% 10%;
      
      .cardano-banner{
        width: 90%;
        height: 20%;
        
        .bg-wrapper{
          
          img{
            
          }
    
        }
      }
    }
  }
  @media (min-width: 750px) {
    .blazing{
      font-size: 3rem;
    }
    .title-logo{
      .title{
        h2 {
          margin: 4% 1% 0% 1%;

        }
      }
      .app-bg{

        img{
          width: 40%;
        }
      }
    }
    .banner-wrapper{
      margin: 47% 10% 0% 10%;
      
      .cardano-banner{
        width: 60%;
        /* height: 20%; */
        
        .bg-wrapper{
          
          img{
            
          }
    
        }
      }
    }
  }
  @media (min-width: 1000px) {
    .blazing {
      font-size: 3.5rem;
      margin: 10% 3% 0% 3%;
    }
    .title-logo{

      .title{

        h2 {
          font-size: 4.5rem;

        }
      }
      .app-bg{
        img{

        }
      }
    }
  }
  @media (min-width: 1200px) {
    .blazing{
      font-size: 4rem;
    }
    .title-logo{

      .title{

        h2 {
          font-size: 5.5rem;
          margin: 3% 1% 0% 1%;

        }
      }
      .app-bg{

        img{
          width: 30%;
        }
      }
    }
    .banner-wrapper{
      margin: 33% 10% 0% 10%;
      
      .cardano-banner{
        width: 40%;
        
        .bg-wrapper{
          
          img{
            
          }
    
        }
      }
    }
    @keyframes Blazing-Box-Inner {
    0%   { box-shadow: 
      inset 0 3px 40px red, 
      inset 0 0 40px red,
      inset 0 0 20px orange,
      inset 0 0 0 yellow,
      inset 0 0 55px yellow,
      inset -6px -5px 55px yellow,
      inset 10px -15px 60px yellow; 
    } 
    25%   { box-shadow: 
      inset 0 3px 40px red, 
      inset 0 0 50px red,
      inset 0 0 30px orange,
      inset 0 0 35px yellow,
      inset -6px -5px 45px yellow,
      inset 7px -10px 60px yellow,
      inset -8px -20px 70px yellow; 
    } 
    50%   { box-shadow: 
      inset 0 3px 40px red, 
      inset 0 0 40px red,
      inset 0 -5px 20px orange,
      inset -6px -5px 55px yellow,
      inset 7px -10px 60px yellow,
      inset -8px -15px 70px yellow,
      inset 6px -25px 80px rgba(255,255,0,0.5); 
    } 
    75%   { box-shadow: 
      inset 0 3px 40px red, 
      inset 0 0 40px red,
      inset 0 -5px 20px orange,
      inset 7px -5px 55px yellow,
      inset -8px -10px 60px yellow,
      inset 6px -20px 80px rgba(255,255,0,0.5),
      inset 0px -30px 90px rgba(255,255,0,0)
    }
    100%   { box-shadow: 
      inset 0 3px 30px red, 
      inset 0 0 30px red,
      inset 0 0 10px orange,
      inset 0 0 0 yellow,
      inset 0 0 45px yellow,
      inset -6px -5px 45px yellow,
      inset 8px -15px 50px yellow; 
    }
  }
  }
  @media (min-width: 1800px) {
    .blazing {
      font-size: 5rem;
      margin: 8% 3% 0% 3%;
    }
    .title-logo{

      .title{

        h2 {
          font-size: 6.5rem;
          margin: 1% 1% 0% 1%;

        }
      }
      .app-bg{

        img{
          top: 20%;
          bottom: 80%;
          width: 30%;
        }
      }
    }
    .banner-wrapper{
      margin: 32% 10% 0% 10%;
      
      .cardano-banner{
        width: 30%;

        
        .bg-wrapper{
          
          img{
            
          }
    
        }
      }
    }
  }
  @media (min-width: 2500px) {
    .blazing {

    }
    .title-logo{

      .title{

        h2 {
          /* font-size: 6.5rem; */
          margin: 0% 1% 0% 1%;

        }
      }
      .app-bg{

        img{
          top: 20%;
          bottom: 80%;
          width: 20%;
        }
      }
    }
    .banner-wrapper{
      margin: 25% 10% 0% 10%;
      
      .cardano-banner{
        width: 25%;

        
        .bg-wrapper{
          
          img{
            
          }
    
        }
      }
    }
  }
`

export default function App() {

  function hide() {
    var reveals = document.querySelectorAll(".hide");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  window.addEventListener("scroll", hide);

  return (
    
      <StyledDiv>
          <div id='header'>
            <Header />
          </div>

          <div class='title-logo'>
            <div class='title'>
              <h2 class='hide'>Ancient Dragon Alliance</h2>
            </div>

            <div class='app-bg'>
              <img src={logo} alt='Ancient Dragon Alliance' />
            </div>
          </div>
          
          {/* <div class='banner-wrapper'>
            <div class='cardano-banner'>
              <div class='bg-wrapper'>
                <img src={CardanoBanner} alt='Only on Cardano' />
              </div>
            </div>
          </div> */}
        <div class="App">
          <AboutProject />

          {/* <div class='carousel'>
            <PFPCarousel1 />
            <PFPCarousel2 />
            <PFPCarousel3 />
          </div> */}
          
          <CarouselContainer />

          <Skymap />
          
          <AboutTeam />
            
          <AboutArtists />
          
          <AboutDevs />
          
          <h3 class='blazing'>WILL YOU HEED THE CALL?</h3>
          <div class='gold-line' />
          {/* <ModelViewer /> */}
          <FAQ />
          
        </div>

        <div class='footer'>
          <Footer />
        </div>

      </StyledDiv>

  );
}


