import React from "react";
import styled from "styled-components";
import twitter from '../../../images/twitter.png';

import PFPOsiris from "../../../images/Osiris.png";


const StyledDiv = styled.div`

    max-width: 100%;
    max-height: 100%;
    
    display: flex;
    align-items: center;
    flex-direction: column;

    .card-container{
        max-width: 100%;
        max-height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        width: 100%;
        height: 100%;
        margin: 6% 7% 5% 0;
        /* padding: 2% 1%; */
        border-radius: 10px;
        
        .intro-container{
    
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: lightcoral;
            /* max-height: 100%; */
            width: 80%;
            /* height: 80%; */
            padding: 5%;
            color: white;
            background-color: #101010;
            border-radius: 10px;
            font-size: 1rem;
            filter: drop-shadow(0 0.3rem 0.3rem black);
    
    
            
            h3{
                font-family: 'New Rocker', cursive;
                padding: 0;
                letter-spacing: 3px;
                font-size: 1.9rem;
                text-align: center;
                font-weight: bold;
                background-image: linear-gradient(
                    to right,
                    #462523 0,
                    #cb9b51 2%, 
                    #f6e27a 45%,
                    #f6f2c0 50%,
                    #f6e27a 55%,
                    #cb9b51 98%,
                    #462523 100%
                );
                color:transparent;
                -webkit-background-clip: text;
                background-clip: text;
                margin: 5% 1% 2% 1%;
                /* text-shadow: 0 1px 30px violet; */
                -webkit-text-stroke-width: 0.1px;
                -webkit-text-stroke-color: #1a1a00;
            }

            img{
                width: 100%;
                height: auto;
                margin: 10% 0;
                border-radius: 10px;
                filter: drop-shadow(0 0.3rem 0.3rem black);

            }
            p{
                margin: 5% 0;
            }
        }
    }
    @media (min-width: 400px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1rem;
                .badge{
                    
                    h3{
                        font-size: 1.9rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 10% 0;
    
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 750px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1rem;
                .badge{
                    width: 80%;
                    h3{
                        font-size: 1.9rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 10% 0;
    
                    }
                    a{
                        img{
                            width: 40%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1000px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1.1rem;
                .badge{
                    width: 85%;
                    h3{
                        font-size: 2.3rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 10% 0;
    
                    }
                    a{
                        img{
                            width: 40%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1200px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1.1rem;
                .badge{
                    width: 90%;
                    h3{
                        font-size: 3rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 5% 0;
    
                    }
                    a{
                        img{
                            width: 30%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1800px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1.1rem;
                .badge{
                    width: 75%;
                    h3{
                        font-size: 3rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 5% 0;
    
                    }
                    a{
                        img{
                            width: 30%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
`

export default function Osiris() {
    return (
        <StyledDiv>
            <div class="card-container">
                <div class="intro-container">
                    <div class='badge'>
                        <h3>Osiris Green</h3>
                        <div>
                            <img src={PFPOsiris} alt="OsirisGreen"/>
                        </div>
                        <a href="https://mobile.twitter.com/thompson2018m"><img src={twitter} alt="@Thompson2018M"/></a>
                    </div>
                    <div class='team-intro'>
                        <div>
                            <p>
                                Hey! Some of you know me as OsirisGreen#5136. My real name is Jason Thompson, I'm a devoted husband and loving father of 2 twin, 13 year old boys. I'm 36 years old and live in British Columbia, Canada 🇨🇦 
                            </p>
                            <p>
                                I work in the IT/Tech service industry. I have multiple certifications for IT, Windows/Server administration MCSA,MCSE,A+ etc.
                            </p>
                            <p>
                                I began my crypto journey in 2014 when I was mining Doge with my 2 HD7850's. I gambled it all away on a fun crypto poker site, thinking it probably wouldn't be worth anything lol. Had I kept what I had mined until 2 years ago, I would have walked away with a cool $780,000 USD. Live and learn I suppose. 😅
                            </p>
                            <p>
                                The world of crypto today is much different then it was back then! We have smart contracts now and a vast amount more technological development. I'm excited for where blockchain technology is taking us, specifically Cardano itself.
                            </p>
                            <p>
                                I'm a classic NFT/crypto enthusiast. I have a substantial amount of experience across more then 100 Cardano NFT projects. I've been a Community Manager and a mod in a number of projects. I'm very excited to bring all of that experience to this project and use it to its fullest potential. I can't wait to show you all of the amazing things we have planned! I really want to show you what it's like to take care of your community first. ❤️ 

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </StyledDiv>
    );
}