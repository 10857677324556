import React, {useEffect} from "react";
import styled from "styled-components";
import FAQAccordion from "../hooks/FAQAccordion";

const StyledDiv = styled.div`
    *{
        /* border: 2px dotted red; */
    }
    max-width: 100%;
    max-height: 100%;
    /* width: 100%; */
    /* height: 80vh; */
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: lightcoral; */

    .blazing-faq {
        /* display: inline-block; */
        margin: 40% 3% 10% 3%;
        width: 100%;
        /* z-index: -1; */
        color: whitesmoke;
        letter-spacing: 3px;
        font-family: 'New Rocker', cursive;

        font-size: 4rem;
        text-align: center;

        /* color: rgb(255, 115, 0); */
        /* font-size: 100px; */
        line-height: 80px;
        /* min-width: 50px; */
        outline: none;
        vertical-align: middle;
        
        text-shadow:
            0 3px 20px red,
            0 0 20px red,
            0 0 10px orange,
            4px -5px 6px yellow,
            -4px -10px 10px yellow,
            0 -10px 30px yellow;
        animation: 2s Blazing infinite alternate linear;
    }


    .faq-container{
        width: 80%;
        max-height: 100%;
        margin: 10% 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* background-color: lightcyan; */
        padding: 6% 3%;
        border-radius: 10px;
        background-image: linear-gradient(
            to right,
            #462523 0,
            #cb9b51 22%, 
            #f6e27a 45%,
            #f6f2c0 50%,
            #f6e27a 55%,
            #cb9b51 78%,
            #462523 100%
            );
            filter: drop-shadow(0 0.3rem 0.3rem orange);
            
            
        .question{
            /* display: flex; */
            /* justify-content: center; */
            /* align-items: center; */
            width: 90%;
            margin: 2% 0;
            border-radius: 10px;
            background-color: #1a1a1a;
            filter: drop-shadow(0 0.3rem 0.3rem black);
            
        
            
            .accordion {
                /* display: flex;
                justify-content: center;
                align-items: center; */
                border-radius: 10px;
                
                font-size: .7rem;
                background-color: #1a1a1a;
                color: whitesmoke;
                cursor: pointer;
                padding: 4%;
                width: 100%;
                border: none;
                text-align: left;
                outline: none;
                transition: 0.4s;
                /* margin: 0 0 5% 0; */
                
            }
            .accordion:hover {
                background-color: #333333;
                /* color: whitesmoke; */

            }

            .active {
                background-color: #333333;

            }
            .panel {
                padding: 0 5%;
                /* background-color: white; */
                color: whitesmoke;
                max-height: 0;
                overflow: hidden;
                border-radius: 10px;
                
                transition: max-height 0.2s ease-out;
                p{
                    font-size: .7rem;
                    text-align: left;
                    margin: 5%;
                }
            }
        }
    }
    @media (min-width: 750px) {
        .blazing-faq {
            font-size: 6rem;
            margin: 50% 3% 15% 3%;

        }


        .faq-container{
            width: 80%;
            padding: 2% 0%;
            margin: 8% 0;

            .question{
                width: 90%;
                margin: 2% 0;
                filter: drop-shadow(0 0.3rem 0.3rem black);
                
            
                
                .accordion {
                    
                    font-size: .9rem;
                    padding: 3%;
                    
                }
                .accordion:hover {

                }

                .active {

                }
                .panel {
                    padding: 0 5%;
                    
                    p{
                        font-size: .9rem;
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1000px) {
        .blazing-faq {
            font-size: 6rem;
            margin: 50% 3% 15% 3%;

        }


        .faq-container{
            width: 80%;
            padding: 1% 0%;
            margin: 4% 0;
            .question{
                width: 95%;
                margin: 1% 0;
                
                .accordion {
                    
                    font-size: .9rem;
                    padding: 2%;
                    
                }
                .accordion:hover {

                }

                .active {

                }
                .panel {
                    padding: 0 5%;
                    
                    p{
                        font-size: .9rem;
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1000px) {
        .blazing-faq {
            font-size: 6rem;
            margin: 50% 3% 15% 3%;

        }


        .faq-container{
            width: 50%;
            padding: 1% 0%;
            margin: 4% 0;
            .question{
                width: 95%;
                margin: 1% 0;
                
                .accordion {
                    
                    font-size: 1.1rem;
                    padding: 2%;
                    
                }
                .accordion:hover {

                }

                .active {

                }
                .panel {
                    padding: 0 5%;
                    
                    p{
                        font-size: 1.1rem;
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1800px) {
        .blazing-faq {
            font-size: 6rem;
            margin: 50% 3% 15% 3%;

        }


        .faq-container{
            width: 30%;
            padding: .5% 0%;
            margin: 4% 0;
            .question{
                width: 95%;
                margin: 1% 0;
                
                .accordion {
                    
                    font-size: 1.1rem;
                    padding: 2%;
                    
                }
                .accordion:hover {

                }

                .active {

                }
                .panel {
                    padding: 0 5%;
                    
                    p{
                        font-size: 1.1rem;
                        margin: 5% 0;
                    }
                }
            }
        }
    }

`


export default function FAQTest2() {
    useEffect(() => {
        FAQAccordion();
    }, [])
    

    return (
        <StyledDiv id='faq'>
            <div class='title-faq'>
                <h2 class='blazing-faq'>
                    F A Q
                </h2>
            </div>
            <div class='faq-container'>
                
                <div class='question'>
                    <button class="accordion">What are your collection sizes?</button>
                    <div class="panel">
                        <p>
                            1,500 Dragon Relics, 5,555 Dragon/Human Hybrids
                        </p>
                    </div>
                </div>

                <div class='question'>
                    <button class="accordion">Wen Mint?</button>
                    <div class="panel">
                        <p>
                            The Dragon Relic mint was on October 9th, 2022.  Our 2d, pfp collection will be minting at the end of November/beginning of December.
                        </p>
                    </div>
                </div>

                <div class='question'>
                    <button class="accordion">Who created your NFTs?</button>
                    <div class="panel">
                        <p>
                            The Leon Art Group created our 3d Relics, and the team behind Space Pugs Alpha and Frog Factory created our 2d human/dragon hybrids
                        </p>
                    </div>
                </div>

                <div class='question'>
                    <button class="accordion">Will I receive royalties from holding a Dragon Relic?</button>
                    <div class="panel">
                        <p>
                            Absolutely!  50% of royalties from secondary sales will be reinvested back to  holders of both the Ancient Dragon and Heritage relics as giveaways and prizes
                        </p>
                    </div>
                </div>

                <div class='question'>
                    <button class="accordion">How many whitelists in your main collection will I receive for holding a Dragon Relic?</button>
                    <div class="panel">
                        <p>
                            Ancient Dragon Relic holders will be whitelisted for 3 NFTs within our main pfp collection.  Heritage Relic holders will be whitelisted for 4 NFTs within our main pfp collection.
                        </p>
                    </div>
                </div>

                <div class='question'>
                    <button class="accordion">Do I receive a discount on mints for holding Dragon Relics?</button>
                    <div class="panel">
                        <p>
                            You will receive a discount on every collection we create for this project! 
                        </p>
                    </div>
                </div>

                <div class='question'>
                    <button class="accordion">Do you have a whitepaper?</button>
                    <div class="panel">
                        <p>
                            We have both a litepaper and a clan paper, and both will be releasing before our main pfp collection.  The litepaper will be an overview of our project, and the clan paper will cover the specifics of the Clan Wars.
                        </p>
                    </div>
                </div>

                <div class='question'>
                    <button class="accordion">What if I have more questions?</button>
                    <div class="panel">
                        <p>
                            We would absolutely love to answer all of the questions you may have!  Pop into our discord and ask as many questions as you’d like.
                        </p>
                    </div>
                </div>
            </div>
        </StyledDiv>
    );
}