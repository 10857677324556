import React from "react";
import styled from "styled-components";
import twitter from '../images/twitter.png';

import PFPArtists01 from "../images/artists/PFP-Artist-01.png";
import PFPArtists02 from "../images/artists/PFP-Artist-02.png";
import PFPArtists03 from "../images/artists/PFP-Artist-03.png";
import PFPArtists04 from "../images/artists/PFP-Artist-04.png";
import PFPArtists05 from "../images/artists/PFP-Artist-05.png";
import PFPArtists06 from "../images/artists/PFP-Artist-06.png";


const StyledDiv = styled.div`
    *{
        /* border: 2px dotted orange; */
        margin: 3%;

    }
    max-width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    /* margin: 10% 0; */

    .blazing-artists {
        /* display: inline-block; */
        margin: 20% 0% 10% 0%;

        /* z-index: -1; */
        color: whitesmoke;
        letter-spacing: 3px;
        font-family: 'New Rocker', cursive;

        font-size: 1.8rem;
        text-align: center;

        /* color: rgb(255, 115, 0); */
        /* font-size: 100px; */
        line-height: 80px;
        /* min-width: 50px; */
        outline: none;
        vertical-align: middle;
        
        text-shadow:
            0 3px 20px red,
            0 0 20px red,
            0 0 10px orange,
            4px -5px 6px yellow,
            -4px -10px 10px yellow,
            0 -10px 30px yellow;
        animation: 2s Blazing infinite alternate linear;
    }

    .link-container-artists{
        display: flex;
        /* flex-direction: column; */
        width: 90%;
        /* margin: 0 10%; */
        /* background-color: palevioletred; */
        justify-content: center;
        align-content: center;
        margin: 0 0 15% 5% ;
        .card-container-artists{
            display: flex;
            flex-direction: row;
            background-image: linear-gradient(
                to right,
                #462523 0,
                #cb9b51 22%, 
                #f6e27a 45%,
                #f6f2c0 50%,
                #f6e27a 55%,
                #cb9b51 78%,
                #462523 100%
            );
            border-radius: 10px;
            filter: drop-shadow(0 0.3rem 0.3rem orange);

            max-width: 100%;
            max-height: 100%;

            background-color: yellowgreen;
            .pfp-artists{
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-direction: column;
                /* z-index: 132; */
                /* background-color: lavenderblush; */
                /* margin: 4% 3% 4% 3%; */
                width: 30%;

                .artist{
                    width: 100%;
                    height: 100%;
                    /* position: relative; */
                    img{
                        width: 100%;
                        height: auto;
                        border-radius: 10px;
                        filter: drop-shadow(0.3rem 0.3rem 0.3rem black);
                        margin: 5%;
                    }
                    p{
                        text-align: center;
                        color: whitesmoke;
                        font-size: .8rem;
                        /* position: absolute;
                        bottom: 0; */
                    }

                }
    
            } 
            .brief-container-artists{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                /* flex: 1; */
                width: 70%;
                /* height: 70%; */
                /* padding: 5%; */
                border-radius: 10px;

                background-color: #101010;

                /* margin: 5% 2% 3% 2% ; */
                filter: drop-shadow(0 0.3rem 0.3rem black);

                
                p{
                    font-size: 1rem;
                    text-align: center;
                    color: whitesmoke;
                    margin: 10%;
                }
                .card-links-artists{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                    a{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50%;
                        height: auto;
                        z-index: 132;
                        /* z-index: 123; */
                        img{
                            /* animation: flickerAnimation 2s infinite; */
                            width: 100%;
                            height: auto;
                            border-radius: 10px;
                            filter: drop-shadow(0.2rem 0.2rem 0.1rem black);
                        }
                    }
                }
            }
            
        }
    }

    @media (min-width: 400px) {
        .blazing-artists {
            font-size: 2.8rem;
        }
        .link-container-artists{

            .card-container-artists{
                padding: 2%;
                .pfp-artists{
                    /* margin: 3% 3% 0% 3%; */
                    
                    .artist{
                        img{
                            /* margin: 10% 0; */
                        }
                        p{
                            font-size: 1.3rem;
                        }
                    }
                }
                .brief-container-artists{
                    /* margin: 5% 2% 3% 2% ; */
                    
                    p{
                        /* margin: 0; */
                        font-size: 1.1rem;
                    }
                    .card-links-artists{
                        
                        a{
                            
                            img{
                                
                            }
                        }
                    }
                }
            }
        }

    }

    @media (min-width: 750px) {
        *{
            /* border: 2px dotted orange; */
        }
        .blazing-artists {
            margin: 10% 0% 5% 0%;
            font-size: 4rem;

        }
        .link-container-artists{
            width: 60%;
            margin: 0% 20% ;
            /* background-color: lightcoral; */
            .card-container-artists{
                .pfp-artists{
                    /* width: 15%; */
                    /* margin: 5% 2% 2% 2%; */
                    .artist{
                        img{
                            /* margin: 10% 0; */
                        }
                        p{
                            font-size: 1.4rem;
                        }
                    }
                }
                .brief-container-artists{
                    /* margin: 3% 2% 5% 2% ;
                    margin: 5%; */
                    width: 90%;
                    /* height: 90%; */
                    p{
                        /* font-size: 1.5rem; */
                        /* margin: 0 5%; */
                    }
                    .card-links-artists{
                        
                        a{
                            
                            img{
                                
                            }
                        }
                    }
                }
            }
        }

    }
    @media (min-width: 1000px) {
        .blazing-artists {
            font-size: 4rem;
            margin: 8% 0% 1% 0%;

        }
        .link-container-artists{
            width: 90%;
            margin: 0 5%;

            .card-container-artists{
                flex-direction: column;
                /* justify-content: center; */
                align-items: center;
                padding: 0 2%;
                .pfp-artists{
                    width: 100%;
                    margin: 2% 0 0 0;
                    flex-direction: row;
                    
                    /* margin: 6% 5% 5% 5%; */
                    .artist{
                        img{
                            /* margin: 10% 0; */
                        }
                        p{
                            /* font-size: 1.4rem; */
                        }
                    }
                }
                .brief-container-artists{
                    margin: 0% 3% 1% 3%;
                    width: 100%;
                    height: 60%;
                    
                    p{
                        font-size: 1.2rem;
                        margin: 3% ;
                    }
                    .card-links-artists{
                        
                        a{
                            
                            img{
                                
                            }
                        }
                    }
                }
            }
        }

    }
    @media (min-width: 1200px) {
        .blazing-artists {
            /* font-size: 4rem; */
            margin: 8% 0% 1% 0%;

        }
        .link-container-artists{
            width: 70%;
            margin: 0 15%;

            .card-container-artists{
                /* flex-direction: column; */
                /* align-items: center; */
                /* padding: 0 2%; */
                .pfp-artists{
                    /* width: 100%; */
                    /* margin: 2% 0 0 0; */
                    /* flex-direction: row; */
                    
                    .artist{
                        img{
                            margin: 1% 0 0 0;
                        }
                        p{
                            /* font-size: 1.4rem; */
                        }
                    }
                }
                .brief-container-artists{
                    /* width: 100%; */
                    /* height: 50%; */
                    margin: 0 0 3% 0;
                    
                    p{
                        /* font-size: 1.2rem; */
                        margin: 2% ;
                    }
                    .card-links-artists{
                        
                        a{
                            
                            img{
                                
                            }
                        }
                    }
                }
            }
        }

    }
    @media (min-width: 1800px) {
        .blazing-artists {
            margin: 3% 0% 1% 0%;

        }
        .link-container-artists{
            width: 50%;
            margin: 0 25% 0 25% ;

            .card-container-artists{
                .pfp-artists{
                    /* margin: 6% 5% 5% 5%; */
                    img{
                        /* margin: 10% 2% 10% 2%; */

                    }
                }
                .brief-container-artists{
                    
                    p{
                        /* font-size: 2rem; */
                    }
                    .card-links-artists{
                        
                        a{
                            
                            img{
                                
                            }
                        }
                    }
                }
            }
        }

    }

`


export default function AboutArtists() {
    return (
        <StyledDiv id="the-artists">
            <h2 class="blazing-artists">PFP Artists</h2>
            {/* <h3 class="Golden">COMING SOON</h3> */}
            <div class="link-container-artists">

                    <div class="card-container-artists">
                        
                        <div class="pfp-artists">
                            <div class='artist'>
                                <img src={PFPArtists01} alt="Jeff"/>
                                <p>
                                    Jeff 
                                </p>
                            </div>
                            <div class='artist'>
                                <img src={PFPArtists02} alt="Keaton"/>
                                <p>
                                    Keaton 
                                </p>
                            </div>
                            <div class='artist'>
                                <img src={PFPArtists03} alt="Ob"/>
                                <p>
                                    Ob 
                                </p>
                            </div>
                            <div class='artist'>
                                <img src={PFPArtists04} alt="Patrick"/>
                                <p>
                                    Patrick 
                                </p>
                            </div>
                            <div class='artist'> 
                                <img src={PFPArtists05} alt="Waymon"/>
                                <p>
                                    Waymon 
                                </p>
                            </div>
                            <div class='artist'> 
                                <img src={PFPArtists06} alt="Darrin"/>
                                <p>
                                    Darrin 
                                </p> 
                            </div>
                        </div>
                       
                        <div class="brief-container-artists">
                            {/* <p>Perplexus Labs</p> */}
                            <p>
                                We are the artists of Frog Factory, Spacepugs, and now the Ancient Dragon Alliance! We sincerely couldn’t be more thrilled with the results! 
                            </p>
                            <p>    
                                It is our greatest joy to bring the CNFT community the best art there is to offer, and we are just getting started! The sky's the limit and we aim for the Moon. 
                            </p>
                            <p>     
                                Art isn’t just a hobby for us, it's our life. All three of us are tattoo artists in the real world, and we love to bring the joy of art to another community bridging the gaps! 
                            </p>
                            <p> 
                                Frog around and find out!
                            </p>
                            {/* <div class="card-links">
                                <a href="#Osiris">
                                <img src={intro} alt="Introduction"/> 
                                </a>
                                <a href="https://twitter.com/PerplexusLabs"><img src={twitter} alt="@PerplexusLabs"/></a>
                            </div> */}
                        </div>
                    </div>
            </div>
            {/* <div class='gold-line' /> */}

        </StyledDiv>
    );
}