import React from "react";
import styled from "styled-components";
import twitter from '../../../images/twitter.png';

import PFPBlayno from "../../../images/Blayno.png";


const StyledDiv = styled.div`

    max-width: 100%;
    max-height: 100%;
    
    display: flex;
    align-items: center;
    flex-direction: column;

    .card-container{
        max-width: 100%;
        max-height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        width: 100%;
        height: 100%;
        margin: 6% 7% 5% 0;
        /* padding: 2% 1%; */
        border-radius: 10px;
        
        .intro-container{
    
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: lightcoral;
            /* max-height: 100%; */
            width: 80%;
            /* height: 80%; */
            padding: 5%;
            color: white;
            background-color: #101010;
            border-radius: 10px;
            font-size: 1rem;
            filter: drop-shadow(0 0.3rem 0.3rem black);
    
    
            
            h3{
                font-family: 'New Rocker', cursive;
                padding: 0;
                letter-spacing: 3px;
                font-size: 1.9rem;
                text-align: center;
                font-weight: bold;
                background-image: linear-gradient(
                    to right,
                    #462523 0,
                    #cb9b51 2%, 
                    #f6e27a 45%,
                    #f6f2c0 50%,
                    #f6e27a 55%,
                    #cb9b51 98%,
                    #462523 100%
                );
                color:transparent;
                -webkit-background-clip: text;
                background-clip: text;
                margin: 5% 1% 2% 1%;
                /* text-shadow: 0 1px 30px violet; */
                -webkit-text-stroke-width: 0.1px;
                -webkit-text-stroke-color: #1a1a00;
            }

            img{
                width: 100%;
                height: auto;
                margin: 10% 0;
                border-radius: 10px;
                filter: drop-shadow(0 0.3rem 0.3rem black);

            }
            p{
                margin: 5% 0;
            }
        }
    }
    @media (min-width: 400px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1rem;
                .badge{
                    
                    h3{
                        font-size: 1.9rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 10% 0;
    
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 750px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1rem;
                .badge{
                    width: 80%;
                    h3{
                        font-size: 1.9rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 10% 0;
    
                    }
                    a{
                        img{
                            width: 40%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1000px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1.1rem;
                .badge{
                    width: 85%;
                    h3{
                        font-size: 2.3rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 10% 0;
    
                    }
                    a{
                        img{
                            width: 40%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1200px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1.1rem;
                .badge{
                    width: 90%;
                    h3{
                        font-size: 3rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 5% 0;
    
                    }
                    a{
                        img{
                            width: 30%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1800px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1.1rem;
                .badge{
                    width: 75%;
                    h3{
                        font-size: 3rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 5% 0;
    
                    }
                    a{
                        img{
                            width: 30%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
`

export default function Blayno() {
    return (
        <StyledDiv>
            <div class="card-container">
                <div class="intro-container">
                    <div class='badge'>
                        <h3>Blayno</h3>
                        <div>
                            <img src={PFPBlayno} alt="Blayno"/>
                        </div>
                        <a href="https://mobile.twitter.com/BlaneElferdink"><img src={twitter} alt="@BlaneElferdink"/></a>
                    </div>
                    <div>
                        <p>
                            You might know me as Blayno. I think a fair amount of you already know me as Blane Elferdink. I’ve been doxxed in this space since I joined the scene. I have been happily married to my wife Amy for almost 5 years and we have a daughter on the way that is expected to be born in late October.
                        </p>
                        <p>
                            I spend my days working as an accountant/financial analyst. I’ve been doing that for the better part of a decade. I have an MBA from UCF and a 4.0 GPA.
                        </p>
                        <p>
                            My journey through crypto hasn’t been near as long as many others in this space, but I’m a quick study. I’ve got a solid understanding of how blockchains functions as a data structure. I’ve done a deep dive on the fundamentals in several major blockchains, but I have an increased focus and competency with Cardano which is by far my favorite chain. 
                        </p>
                        <p>
                            I’ve been community manager for Lion Legends and functioned as a key part of Outer Space Society’s Space Staff in a Senior role. I’ve learned a lot about the CNFT space and made a number of connections with awesome people in that time.
                        </p>
                        <p>
                            Each of the previous three points are very relevant, because I feel very strongly that the people who have strong competencies in business, the technical capabilities & limitations of Cardano, AND have actively managed large communities in the CNFT space are in short supply. The overlapping of those skillsets comes in very handy in guiding a project along an optimal course for success. This is the first project I’ve had the luxury of being with from an early point in its development. I’m very passionate about doing everything I can to make it wildly successful for everyone involved. That starts with a holders first mentality, but also includes making sure staff of all levels are properly compensated and well taken care of.
                        </p>
                    </div>
                </div>
            </div>
        </StyledDiv>
    );
}