import React from "react";
import styled from "styled-components";

import Scry from "../images/scry2.png";
import skymap001 from "../images/ADA_Skymap001.png";
import skymap002 from "../images/ADA_Skymap002.png";
import skymap003 from "../images/ADA_Skymap003.png";



const StyledDiv = styled.div`
    *{
        /* border: 2px dotted red; */
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    /* margin: 30% 40%  30% 0; */

    .blazing-skymap {
        margin: 5%;
        
        color: whitesmoke;
        letter-spacing: 3px;
        font-family: 'New Rocker', cursive;
        font-size: 3rem;
        text-align: center;
        line-height: 80px;
        outline: none;
        vertical-align: middle;
        text-shadow:
            0 3px 20px red,
            0 0 20px red,
            0 0 10px orange,
            4px -5px 6px yellow,
            -4px -10px 10px yellow,
            0 -10px 30px yellow;
        animation: 2s Blazing infinite alternate linear;
            
    }
    h2{
        padding: 20% 0 0% 0;
    }
    .content-container-skymap{
        display: flex;
        flex-direction: column;
        /* width: 100%; */
        .gold-card-skymap{
            /* display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center; */

            margin: 5% 10% 10% 10%;
            padding: 5%;
            width: 70%;
            height: auto;

            background-image: linear-gradient(
                to right,
                #462523 0,
                #cb9b51 22%, 
                #f6e27a 45%,
                #f6f2c0 50%,
                #f6e27a 55%,
                #cb9b51 78%,
                #462523 100%
            );

            /* line-height: 15px; */
            border-radius: 10px;
            filter: drop-shadow(0 0.3rem 0.3rem orange);

            .card-container-skymap{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;

                max-width: 100%;
                border: 1px solid darkgoldenrod;
                border-radius: 10px;

                background-color: #101010;
                .text-container-skymap{
                    justify-content: center;
                    align-content: center;
                    p{
                        /* padding: 5%; */
                        margin: 5%;
                        /* letter-spacing: 3px; */
                        
                        color: white;
                        font-size: .8rem;
                    }
                }
                .link-container-skymap{
                    display: flex;
                    /* flex-direction: column; */
                    justify-content: center;
                    align-content: center;
                    max-width:height ;
                    a{
                        /* display: block; */
                        width: 100px;
                        height: 100px;
                        margin: 20% 0%;
                        position: relative;
                        border-radius: 50%;
                        z-index: 500;
                        img{
                            width: 100%;
                            height: auto;

                            border-radius: 50%;
                            animation: 2s Glowing infinite alternate linear;
                        }
                    }
                }
            }
        }
    }
    #skymap{
        *{
            /* border: 1px dotted red; */
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;

        max-width: 100%;
        max-height: 100%;
        
        background-color: black;
        border-radius: 10px;
        .main-container{
            background-color: black;
            max-width: 100%;
            max-height: 90%;
            margin: 12% 0% 0 0%;

            
            .outer{
                /* display: flex;
                justify-content: center;
                align-content: center; */
                max-width: 100%;
                max-height: 100%;
                width: 100%;

                /* background-color: lavenderblush; */
                margin: 0%;
                overflow-y: auto;
                .inner{
                    max-width: 100%;
                    margin: 10% 0%;
                    background-color: black;
                    img{
                        width: 100%;
                        height: auto;
                    }
                    p{
                        font-size: 1rem;
                        color: whitesmoke;
                        text-align: center;
                    }
                }
            }
        }
    }
    .close-container{
        max-width: 100%;
        max-height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        /* display: block; */
        
        width: 100%;
        height: 5vh;
        /* margin: 0% 0% 5% 0%; */
        /* padding: 0%; */
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        /* background: #606061; */
        z-index: 600;
        background-image: linear-gradient(
            to right,
            #462523 0,
            #cb9b51 22%, 
            #f6e27a 45%,
            #f6f2c0 50%,
            #f6e27a 55%,
            #cb9b51 78%,
            #462523 100%
        );

        border-radius: 0 0 10px 10px;
        /* opacity: .8; */
        text-align: center;
        font-weight: bold;
        /* z-index: 100000; */

        a {
            /* display: flex;
            justify-content: center;
            align-items: center; */
            color: black;
            text-decoration: none;
            margin: 2% 0 0 0;
            /* top: 0px; */

            position: absolute;
            z-index: 700;

            width: 100%;
            height: 100%;
            /* margin: 0% 50% 0 0; */
            /* padding: 5% 0%; */
            
            /* line-height: 25px; */
            /* position: relative; */
            border-radius: 0 0 10px 10px;
            font-size: 1.5rem;
            font-weight: bold;

            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: black;
            /* -webkit-border-radius: 12px;
            -moz-border-radius: 12px;
            border-radius: 12px; */
            /* -moz-box-shadow: 1px 1px 3px #000;
            -webkit-box-shadow: 1px 1px 3px #000;
            box-shadow:  2px 2px 2px #000; */
            
        }
        .close:hover {
            /* background: darkslategray; */
            color: orange;
        }
    }
    .modal{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;

        position: fixed;
        font-family: Arial, Helvetica, sans-serif;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        z-index: 500;
        opacity:0;
        -webkit-transition: opacity 400ms ease-in;
        -moz-transition: opacity 400ms ease-in;
        transition: opacity 400ms ease-in;

        pointer-events: none;
        overflow: initial
    }
    .modal:target {
        opacity: 1;
        pointer-events: auto;
    }

    /* Animations */
    @keyframes Glowing{
        0% {
            -webkit-box-shadow:0px 0px 25px 8px rgb(193,145,225);
            -moz-box-shadow: 0px 0px 25px 8px rgb(193,145,225);
            box-shadow: 0px 0px 25px 8px rgb(193,145,225);
        }
        25% {
            -webkit-box-shadow:0px 0px 25px 6px rgb(193,145,225);
            -moz-box-shadow: 0px 0px 25px 6px rgb(193,145,225);
            box-shadow: 0px 0px 25px 6px rgb(193,145,225);
        }
        40% {
            -webkit-box-shadow:0px 0px 25px 4px rgb(193,145,225);
            -moz-box-shadow: 0px 0px 25px 4px rgb(193,145,225);
            box-shadow: 0px 0px 25px 4px rgb(193,145,225);
        }
        60% {
            -webkit-box-shadow:0px 0px 25px 2px rgb(193,145,225);
            -moz-box-shadow: 0px 0px 25px 2px rgb(193,145,225);
            box-shadow: 0px 0px 25px 2px rgb(193,145,225);
        }
        80% {
            -webkit-box-shadow:0px 0px 25px 4px rgb(193,145,225);
            -moz-box-shadow: 0px 0px 25px 4px rgb(193,145,225);
            box-shadow: 0px 0px 25px 4px rgb(193,145,225);
        }
        100% {
            -webkit-box-shadow:0px 0px 40px 6px rgb(193,145,225);
            -moz-box-shadow: 0px 0px 40px 6px rgb(193,145,225);
            box-shadow: 0px 0px 40px 6px rgb(193,145,225);
        }
    }

    /* Media Queries */

    @media (min-width: 400px) {

        .blazing-skymap {

        }
        h2{

        }
        .content-container-skymap{

            .gold-card-skymap{

                .card-container-skymap{

                    .text-container-skymap{

                        p{
                            font-size: 1rem;
                        }
                    }

                    .link-container-skymap{
                        
                        a{
                            
                            img{
            
                            }
                        }
                    }
                }
            }
        }
       
        #skymap{
            
            .main-container{

                .outer{

                    .inner{

                        img{
                            
                        }
                        p{

                        }
                    }
                }
            }
        }
        .close-container{
            a{
                margin: 4% 0 0 0;

            }
            .close:hover {
    
            }
        }
        .modal{

        }
        .modal:target {

        }
    }
    @media (min-width: 750px) {

        .blazing-skymap {
            font-size: 4rem;
        }
        h2{
            padding: 8% 0 0% 0;

        }
        .content-container-skymap{

            .gold-card-skymap{
                margin: 15% 3% 5% 3%;
                padding: 2%;
                width: 90%;

                .card-container-skymap{
                    flex-direction: row;
                    
                    .text-container-skymap{

                        p{
                            

                        }
                    }

                    .link-container-skymap{
                        margin: 25% 25% 0 0;
                        a{
                            
                            img{
            
                            }
                        }
                    }
                }
            }
        }

        #skymap{
            
            .main-container{
                max-height: 90%;
                margin: 5% 0% 0 0%;
                .outer{
                    margin: 0% 10%;
                    width: 80%;

                    .inner{

                        img{
                            
                        }
                        p{

                        }
                    }
                }
            }
        }
        .close-container{
            a{
                margin: 1% 0 0 0;

            }
            .close:hover {

            }
        }
        .modal{

        }
        .modal:target {
            
        }
    }
    @media (min-width: 1000px) {

        .blazing-skymap {

        }
        h2{
            font-size: 4rem;

        }
        .content-container-skymap{

            .gold-card-skymap{
                margin: 10% 3% 2% 3%;
                .card-container-skymap{

                    .text-container-skymap{
                        width: 70%;
                        margin: 5%;
                        p{
                            font-size: 1.3rem;
                            /* padding: 3%; */

                        }
                    }

                    .link-container-skymap{
                        
                        a{
                            /* margin: 10%; */
                            width: 200px;
                            height: 200px;
                            img{
            
                            }
                        }
                    }
                }
            }
        }

        #skymap{
            
            .main-container{

                .outer{

                    .inner{

                        img{
                            
                        }
                        p{

                        }
                    }
                }
            }
        }
        .close-container{
            a{
                margin: 2% 0 0 0;

            }
            .close:hover {

            }
        }
        .modal{

        }
        .modal:target {
            
        }
    }
    @media (min-width: 1200px) {

        .blazing-skymap {

        }
        h2{
            padding: 2% 0 0% 0;

        }
        .content-container-skymap{

            .gold-card-skymap{
                margin: 5% 3% 2% 3%;

                .card-container-skymap{

                    .text-container-skymap{

                        p{
                            
                        }
                    }

                    .link-container-skymap{
                        
                        a{
                            
                            img{
            
                            }
                        }
                    }
                }
            }
        }

        #skymap{
            
            .main-container{

                .outer{

                    .inner{

                        img{
                            
                        }
                        p{

                        }
                    }
                }
            }
        }
        .close-container{
            a{
                margin: 1.5% 0 0 0;

            }
            .close:hover {

            }
        }
        .modal{

        }
        .modal:target {
            
        }
    }
    @media (min-width: 1800px) {

        .blazing-skymap {
            font-size: 7rem;

        }
        h2{

        }
        .content-container-skymap{

            .gold-card-skymap{
                margin: 3% 7% 2% 13%;
                width: 70%;

                .card-container-skymap{

                    .text-container-skymap{

                        p{
                            font-size: 2rem;
                            margin: 2% 5%;
                        }
                    }

                    .link-container-skymap{
                        
                        a{
                            width: 300px;
                            height: 300px;
                            margin: 10% 0%;
                            img{
            
                            }
                        }
                    }
                }
            }
        }

        #skymap{
            
            .main-container{

                .outer{
                    width: 60%;
                    margin: 0% 20%;

                    .inner{

                        img{
                            
                        }
                        p{

                        }
                    }
                }
            }
        }
        .close-container{
            a{
                margin: 2% 0 0 0;

            }
            .close:hover {

            }
        }
        .modal{

        }
        .modal:target {
            
        }
    }
    @media (min-width: 2500px) {

        .blazing-skymap {

        }
        h2{
            padding: 0;
        }
        .content-container-skymap{

            .gold-card-skymap{
                margin: 0% 25% 2% 25%;
                width: 50%;
                padding: 1%;

                .card-container-skymap{

                    .text-container-skymap{

                        p{
                            font-size: 1.5rem;
                            margin: 7% 5%;
                        }
                    }

                    .link-container-skymap{
                        
                        a{
                            width: 300px;
                            height: 300px;
                            margin: 10% 0%;
                            img{
            
                            }
                        }
                    }
                }
            }
        }

        #skymap{
            
            .main-container{

                .outer{
                    width: 60%;
                    margin: 0% 20%;

                    .inner{

                        img{
                            
                        }
                        p{

                        }
                    }
                }
            }
        }
        .close-container{
            a{

            }
            .close:hover {

            }
        }
        .modal{

        }
        .modal:target {
            
        }
    }
`

export default function Skymap() {

    return (
        <StyledDiv id="the-skymap">
            <h2 class="blazing-skymap">The Skymap</h2>

            <div class='content-container-skymap'>
                <div class="gold-card-skymap">
                    <div class='card-container-skymap'>
                        <div class='text-container-skymap'>
                            <p>
                                Scry beyond the veil of space and time and learn about what was, what is, and what soon will be.
                            </p>
                            <p>
                                The Skymap is living prophecy, detailing every major step along our journey. Be sure to check back regularly for updates when you need to find your way.
                            </p>
                        </div>
                        <div>
                            <div class="link-container-skymap">
                                <a href="#skymap">
                                    <img src={Scry} alt="Scry the Map" /> 
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* <div class='gold-line' /> */}

            {/* MODAL */}
            <div id='skymap' class='modal'>
                <div class='main-container'>
                    <div class='outer'>
                        <div class="close-container">
                            <a href="#the-skymap" title="Close" class="close">
                                CLOSE
                            </a>
                        </div>
                        <div class='inner'>
                            <img src={skymap001} alt="Skymap Page 1"/>
                            <img src={skymap002} alt="Skymap Page 2"/>
                            <img src={skymap003} alt="Skymap Page 3"/>
                            <p>
                                

                            </p>
                                
                            
                        </div>
                    </div>
                </div>
            </div>
        </StyledDiv>
    )
}