import React from "react";
import styled from 'styled-components';
import pfp from '../../images/mystery.gif';

import Adapfp013 from '../../images/ADA-PFP/ADA-PFP-013.png';
import Adapfp014 from '../../images/ADA-PFP/ADA-PFP-014.png';
import Adapfp015 from '../../images/ADA-PFP/ADA-PFP-015.png';
import Adapfp016 from '../../images/ADA-PFP/ADA-PFP-016.png';
import Adapfp017 from '../../images/ADA-PFP/ADA-PFP-017.png';



const StyledCarousel3 = styled.div`
    * {
        /* border: 1px dotted orange; */
    }
`

export default function Carousel3() {


    return (
        <StyledCarousel3>
            <div class="slider">
                <div class="slide-track-container slide-track1">

                    {/* FIRST */}
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp013} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp014} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp015} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp016} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp017} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>

                    {/* SECOND */}
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp013} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp014} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp015} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp016} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp017} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>

                </div>
            </div>
        </StyledCarousel3>
    )
}