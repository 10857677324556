import React from "react";
import styled from "styled-components";
import PFPPerplexus from "../images/PerplexusLabs.png";
import twitter from '../images/twitter.png';
import website from '../images/website.png';


const StyledDiv = styled.div`
    *{
        /* border: 2px dotted orange; */

    }
    max-width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    
    
    .blazing-devs {
        /* display: inline-block; */
        margin: 20% 0% 0% 0%;

        /* z-index: -1; */
        color: whitesmoke;
        letter-spacing: 3px;
        font-family: 'New Rocker', cursive;

        font-size: 1.8rem;
        text-align: center;

        /* color: rgb(255, 115, 0); */
        /* font-size: 100px; */
        line-height: 80px;
        /* min-width: 50px; */
        outline: none;
        vertical-align: middle;
        
        text-shadow:
            0 3px 20px red,
            0 0 20px red,
            0 0 10px orange,
            4px -5px 6px yellow,
            -4px -10px 10px yellow,
            0 -10px 30px yellow;
        animation: 2s Blazing infinite alternate linear;
    }

    .link-container-devs{
        display: flex;
        width: 90%;
        justify-content: center;
        align-content: center;
        margin: 0% 5% ;
        .card-container-devs{
            display: flex;
            flex-direction: row;
            background-image: linear-gradient(
                to right,
                #462523 0,
                #cb9b51 22%, 
                #f6e27a 45%,
                #f6f2c0 50%,
                #f6e27a 55%,
                #cb9b51 78%,
                #462523 100%
            );
            border-radius: 10px;
            filter: drop-shadow(0 0.3rem 0.3rem orange);

            max-width: 100%;
            max-height: 100%;

            .pfp-devs{
                display: flex;

                justify-content: center;
                align-items: flex-start;
                height: 100%;
                width: 30%;
                /* height: 30%; */
                
                img{
                    width: 80%;
                    margin: 10% 0%;
                    height: auto;
                    border-radius: 10px;
                    filter: drop-shadow(0.2rem 0.2rem 0.1rem black);
                }
    
            } 
            .brief-container-devs{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                max-height: 100%;
                width: 70%;
                border-radius: 10px;
                background-color: #101010;
                margin: 3% 2%;
                
                p{
                    font-size: 1rem;
                    text-align: center;
                    color: whitesmoke;
                    margin: 10%;
                }
                .card-links-devs{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                    a{
                        margin: 8% 3%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        width: 40%;
                        height: auto;
                        z-index: 132;
                        img{
                            width: 100%;
                            height: auto;
                            border-radius: 5px;
                            filter: drop-shadow(0.2rem 0.2rem 0.1rem black);
                        }
                    }
                }
            }
            
        }
    }
    @media (min-width: 400px) {
        .blazing-devs {
            margin: 20% 0% 5% 0%;

        }
        .link-container-devs{
            

            .card-container-devs{
                .pfp-devs{
                    img{
                        

                    }
                }
                .brief-container-devs{
        
                    p{
                        
                    }
                    .card-links-devs{
                        
                        a{
                            
                            img{
                                
                            }
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 750px) {
        .blazing-devs {
            font-size: 2.8rem;
            margin: 10% 0% 5% 0%;

        }
        .link-container-devs{
            width: 70%;
            margin: 0% 15% ;

            .card-container-devs{
                .pfp-devs{
                    img{
                        

                    }
                }
                .brief-container-devs{
        
                    p{
                        font-size: 1.4rem;
                        margin: 2%;
                    }
                    .card-links-devs{
                        
                        a{
                            
                            img{
                                
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 1000px) {
        .blazing-devs {

        }
        .link-container-devs{

            .card-container-devs{
                .pfp-devs{
                    img{
                        

                    }
                }
                .brief-container-devs{
        
                    p{
                        font-size: 1.4rem;
                        margin: 2%;
                    }
                    .card-links-devs{
                        
                        a{
                            
                            img{
                                width: 60%;
                                margin: 0%;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 1200px) {
        .blazing-devs {
            margin: 7% 0% 5% 0%;

        }
        .link-container-devs{

            .card-container-devs{
                .pfp-devs{
                    img{
                        

                    }
                }
                .brief-container-devs{

                    p{
                        font-size: 1.4rem;
                        margin: 2%;
                    }
                    .card-links-devs{
                        
                        a{
                            
                            img{
                                width: 60%;
                                margin: 0%;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 1800px) {
        .blazing-devs {
            margin: 4% 0% 2% 0%;

        }
        .link-container-devs{
            width: 70%;
            margin: 0% 15% ;

            .card-container-devs{
                /* padding: -10%; */
                align-items: center;
                justify-content: center;

                .pfp-devs{
                    align-items: center;
                    width: 20%;
                    margin: 0% 1%;
                    img{
                        width: 100%;


                    }
                }
                .brief-container-devs{
                    margin: 1% 1% 1% 0;
                    width: 80%;

                    p{
                        font-size: 1.8rem;
                        margin: 5% 0 2% 0;
                    }
                    .card-links-devs{
                        
                        a{
                            
                            img{
                                width: 40%;
                            }
                        }
                    }
                }
            }
        }
    }
`


export default function AboutDevs() {
    return (
        <StyledDiv id="the-developers">
            <h2 class="blazing-devs">PERPLEXUS LABS</h2>
            <div class="link-container-devs">
                <div class="card-container-devs">

                    <div class="pfp-devs">
                        <img src={PFPPerplexus} alt="Perplexus Labs"/> 
                    </div>
                    
                    <div class="brief-container-devs">
                        <p>Perplexus Labs is a blockchain solutions laboratory solving the problems that perplex us all</p>
                        <div class="card-links-devs">
                            <a href="https://perplexus.io" target="_blank" rel='noreferrer'><img src={website} alt="perplexuslabs.io"/></a>
                            <a href="https://twitter.com/perplexus_io" target="_blank" rel='noreferrer'><img src={twitter} alt="@PerplexusLabs"/></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class='gold-line' /> */}

        </StyledDiv>
    );
}