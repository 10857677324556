import React from "react";
import styled from "styled-components";
import aboutpfp from '../images/1080-Marble-Gif.gif';
import printable from '../downloadable/Dragon_stl.rar';

// import relic from '../images/Dragon-Statue.gltf'

const StyledProject = styled.div`

    *{
        /* border: 2px dotted orange; */
    }
    
    max-width: 100%;
    height: auto;
    background-color: transparent 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: space-around;
    margin: 105% 0 0% 0;
    
    .ap-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        max-width: 100%;
        padding: 0%;

        .ap1 {
            margin: 25% 0 0% 0;
            display: flex;
            justify-content: center;
            align-items: center;
    
            width: 70%;
            padding: 5% 5% 4% 5%;
    
            border-radius: 10px;
            filter: drop-shadow(0 0.3rem 0.3rem orange);
    
            background-image: linear-gradient(
                to right,
                #462523 0,
                #cb9b51 22%, 
                #f6e27a 45%,
                #f6f2c0 50%,
                #f6e27a 55%,
                #cb9b51 78%,
                #462523 100%
            );
            
            img{
                width: 100%;
                height: auto;
                border-radius: 10px;
            }
            .model-wrapper{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                background-color: black;
                /* padding: 15% 0; */
                border-radius: 10px;
            }
        }

        .downloadable{
            display: flex;
            /* flex-direction: column; */
            justify-content: center;
            align-content: center;
            width: 25%;
            .gold-card-dl{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;

                margin: 5% 10% 10% 10%;
                padding: 3%;
                width: 80%;
                /* height: auto; */

                background-image: linear-gradient(
                    to right,
                    #462523 0,
                    #cb9b51 22%, 
                    #f6e27a 45%,
                    #f6f2c0 50%,
                    #f6e27a 55%,
                    #cb9b51 78%,
                    #462523 100%
                );

                /* line-height: 15px; */
                border-radius: 10px;
                filter: drop-shadow(0 0.3rem 0.3rem orange);

                .card-container-dl{
                    /* display: flex; */
                    /* flex-direction: column; */
                    justify-content: center;
                    align-content: center;

                    max-width: 100%;
                    border: 1px solid darkgoldenrod;
                    border-radius: 10px;
                    padding: 5%;

                    background-color: #101010;
                    .link-container-dl{
                        /* justify-content: center;
                        align-content: center; */
                        text-align: center;
                        a{
                            margin: 5%;
                            /* letter-spacing: 3px; */
                            
                            color: whitesmoke;
                            text-decoration: none;
                            font-size: 1.2rem;
                        }
                        a:hover { 
                            text-shadow: 0 0 5px #ff0000; 
                        }
                    }
                    
                }
            }
        }

        .ap2{
            padding: 2%;
            margin: 2% 5%;
    
            p{
                margin: 5% 0%;
                color: white;
                font-size: 1.1rem;
                text-align: center;
            }
            
        }
    }
    #the-project{
        margin:0 0 0% 0;
    }
    
    @media (min-width: 400px) {
        margin: 135% 0 0% 0;

        .ap-container{
            .ap1{
                margin: 20% 0 0% 0;
                /* margin: 57% 0 0% 0; */

                img{
    
                }
            }
            .ap2{
                

                p {
                font-size: 1.1rem;
    
                }
            }
        }
    }

    @media (min-width: 750px) {
        margin: 50% 0 0% 0 ;

        .ap-container{
            /* flex-direction: row; */
            align-items: center;
            padding: 0 2%;
            .ap1{
                margin: 10% 0 0% 0;
                padding: 2%;
                width: 35%;
                img{
                    width: 95%;
                }
            }
            .ap2{
                width: 60%;
                margin: 0;
                p {

                font-size: 1rem;
    
                }
            }
        }
    }
    @media (min-width: 1000px) {

        .ap-container{
            
            .ap1{
                /* margin: 10% 0 0% 0; */
                padding: 3%;
                img{
                    width: 100%;
                }
            }
            .ap2{

                p {
                    font-size: 1.1rem;
    
                }
            }
        }
    }
    @media (min-width: 1200px) {
        margin: 35% 0 0% 0 ;

        .ap-container{

            .ap1{
                width: 25%;
                margin: 7% 0 0% 0;
                padding: 2%;

                img{
                    /* width: 80%; */

                }
            }
            .ap2{

                p {
                font-size: 1.3rem;
    
                }
            }
        }
    }
    @media (min-width: 1800px) {
        margin: 40% 0 0% 0 ;
        .downloadable{

        }
        .ap-container{

            .ap1{
                margin: 5% 0 0% 0;
                width: 20%;
                padding: 1.5% 3.5%;

                img{
                    width: 120%;

                }
            }
            .ap2{

                p {
                font-size: 1.5rem;
    
                }
            }
        }
    }
    @media (min-width: 2500px) {
        margin: 30% 0 0% 0 ;

        .blazing{
            
        }
        .ap-container{
            
            .ap1{
                padding: 1% 3%;
                margin: 4% 0 0% 0;


                img{
                    

                }
            }
            .ap2{

                p {
                
    
                }
            }
        }
    }
`

export default function AboutProject() {
    return (
        <StyledProject>
            <div class='ap-container'>
            <div id="the-project" />
                <div class='ap1'>
                    <img id='aboutpfp' src={aboutpfp} alt='Ancient Dragon Alliance' />
                    {/* <div class='model-wrapper'>
                        <model-viewer id='aboutpfp' src={relic} auto-rotate rotation-per-second='70deg' camera-controls touch-action="pan-y"/>
                    </div> */}
                </div>
                <div className="downloadable">
                    <div className="gold-card-dl">
                        <div className="card-container-dl">
                            <div className="link-container-dl">
                                <a href={printable} download>Download 3D Printable stl file</a>

                            </div>
                        </div>
                    </div>
                </div>
                <div class='ap2'>
                    <p>
                        The Ancient Dragon Alliance (A.D.A.) is a lore and story-centric project with hoards of utility behind it. We have a completely doxxed team who bring extensive experience in the NFT and crypto space.  A.D.A. plans to deliver the best Dragon project on Cardano, if not the best project period!
                    </p>
                    <p>
                        The first collection contains 1,500 3D Dragon Relics, meticulously sculpted from stone, marble, and obsidian.  The relics function as our WL genesis token, and will provide discounted whitelists for everything we plan on creating.  The first 800 relics minted will have the chance to upgrade to “Heritage” through a series of snapshots, adding new elements to the NFT and extra utility within the project!
                    </p>
                    <p>
                        Our primary pfp collection consists of 5,555, 2D, Dragon/Human hybrid nfts. This collection will be the only way to participate in the Clan Wars, which will be one of the primary focuses of the project. Every month, 5 clans will battle it out over a 3-week span to earn high value rewards!
                    </p>
                    <p>
                        Our project will deliver unique and amazing events, interactive lore, captivating media, and strongly incentivized holder roles with a range of benefits/earning potential. We have a lot more coming then that but we'd like to not spoil all of the secrets just yet!  Be sure to check out our Discord for a deep-dive of everything we have to offer.
                    </p>
                </div>
            </div>
            <div class='gold-line' />
        </StyledProject>
    );
}