import React from "react";
import styled from "styled-components";


const StyledDiv = styled.div`
    *{
        /* border: 1px dotted orange; */
    }
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 50px;
    /* top: 0px; */
    margin: 0;
    background-color: #0f0f0f;
    
    
    display: block;
    /* position: -webkit-sticky;
    position: sticky;
    top: 0px; */

    .menu-container{
        /* display: inline-block; */
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: baseline;
        width: 0%;
        height: 0%;

        #menuToggle{
            display: block;
            position: relative;
            top: 15px;
            left: 10px;
            height: 0;
            z-index: 10;
            
            -webkit-user-select: none;
            user-select: none;
            a{
                text-decoration: none;
                color: whitesmoke;
                opacity: .7;
                
                transition: color 0.3s ease;
                :hover{
                    color: orange;
            
                }
            }
        
        
            input{
                display: block;
                width: 38px;
                height: 30px;
                position: absolute;
                top: -7px;
                left: -5px;
                cursor: pointer;
                
                opacity: 0; 
                /* visibility: hidden; */
                z-index: 2; 
                
                -webkit-touch-callout: none;
                :checked ~ span{
                    opacity: 1;
                    /* visibility: visible; */
                    transform: rotate(45deg) translate(-2px, -1px);
                    background: #cdcdcd;
                }
            
                :checked ~ span:nth-last-child(3){
                    opacity: 0;
                    transform: rotate(0deg) scale(0.2, 0.2);
                }
                
                :checked ~ span:nth-last-child(2){
                    transform: rotate(-45deg) translate(0, -1px);
                }
        
                :checked ~ ul{
                    transform: none;
                }
            }
        
            span{
                display: block;
                width: 33px;
                height: 4px;
                margin-bottom: 5px;
                position: relative;
                
                background: #cdcdcd;
                border-radius: 3px;
                
                z-index: 1;
                
                transform-origin: 4px 0px;
                
                transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                            background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                            opacity 0.55s ease;
                :first-child{
                    transform-origin: 0% 0%;
                }
                :nth-last-child(2){
                    transform-origin: 0% 100%;
                }
            }
        
        }

        #menu{
    
            position: fixed;
            top: 0px;
            width: 100%;
            margin: -100px 0 0 -50px;
            padding: 50px;
            padding-top: 180px;
            
            background-image: linear-gradient(to right, black, transparent), linear-gradient(black, transparent);
            list-style-type: none;
            -webkit-font-smoothing: antialiased;
            
            transform-origin: 0% 0%;
            transform: translate(-100%, 0);
            transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
            
            border-bottom: 2px solid darkgoldenrod;
            border-right: 2px solid darkgoldenrod;
            border-radius: 0 0 10px 0;
    
            li{
                padding: 5px ;
                font-size: 1.3rem;
            }
        }

        .reveal{
            position: absolute;
            top: 10px;
            left: 50px;
            height: 0;
            transform: translateY(-30px);
            opacity: 0;
            transition: 1s all ease;
            h1{
                width: 100%;
                /* height: 15px; */
                padding: 3%;
                
                font-family: 'New Rocker', cursive;
                letter-spacing: 2px;
                font-size: 1.2rem;
                text-align: center;
                font-weight: bold;
                color:transparent;
    
                background-image: linear-gradient(
                    to right,
                    #462523 0,
                    #cb9b51 2%, 
                    #f6e27a 45%,
                    #f6f2c0 50%,
                    #f6e27a 55%,
                    #cb9b51 98%,
                    #462523 100%
                );
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-stroke-width: 0.1px;
                -webkit-text-stroke-color: #1a1a00;
            }
        }

        .reveal.active{
            transform: translateY(0);
            opacity: 1;
        }
    }
    
    @media (min-width: 400px) {
        .menu-container{

            #menu{
    
            }
            .reveal{
                top: 5px;
                left: 50px;
                h1{
                    font-size: 1.6rem;
                    /* padding: 5% 0% 5% 20%; */
                }
            }
        }
    }

    @media (min-width: 750px) {
        .menu-container{

            #menu{

            }
            .reveal{
                top: 3px;
                left: 45px;
                h1{
                    font-size: 1.8rem;
                    /* padding: 4% 0% 5% 18%; */
                }
            }
        }
    }

    @media (min-width: 1000px) {
        .menu-container{

            #menu{

            }
            .reveal{
                
                h1{
                    /* padding: 4% 0% 5% 20%; */

                }
            }
        }

    }

    @media (min-width: 1200px) {
        .menu-container{

            #menu{

            }
            .reveal{
                
                .h1{
                    font-size: 1.7rem;
                }
            }
        }
    }

    @media (min-width: 1900px) {
        .menu-container{

            #menu{

            }
            .reveal{
                
                .h1{
                    
                }
            }
        }
    }

`

export default function Header() {

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
    }
    window.addEventListener("scroll", reveal);
    
    
    function closeMenu() {
       
        document.getElementById('menubutton').checked=false;    
    }

    return (
        <StyledDiv>
            
            <div class='menu-container'>
                <nav role="navigation">
                    <div id="menuToggle">
                        
                        <input type="checkbox" id='menubutton'/>
                        
                        <span></span>
                        <span></span>
                        <span></span>

                        <ul id="menu">
                            <a href="#" onClick={() => closeMenu()}><li>Home</li></a>
                            <a href="#the-project" id='projectLink' onClick={() => closeMenu()}><li>The Project</li></a>
                            <a href="#the-skymap" onClick={() => closeMenu()}><li>The Skymap</li></a>
                            <a href="#the-team" onClick={() => closeMenu()}><li>The Team</li></a>
                            <a href="#the-artists" onClick={() => closeMenu()}><li>The Artists</li></a>
                            <a href="#the-developers" onClick={() => closeMenu()}><li>The Developers</li></a>
                            <a href="#faq" onClick={() => closeMenu()}><li>FAQ</li></a>
                            <a href="#socials" onClick={() => closeMenu()}><li>Socials</li></a>
                        </ul>

                    </div>
                </nav>
                <div class="reveal">
                    <h1 class='golden-title'>Ancient Dragon Alliance</h1>
                </div>
            </div>
            
        </StyledDiv>
    );
}