import React from "react";
import styled from 'styled-components';
import pfp from '../../images/mystery.gif';


import Adapfp006 from '../../images/ADA-PFP/ADA-PFP-006.png';
import Adapfp007 from '../../images/ADA-PFP/ADA-PFP-007.png';
import Adapfp008 from '../../images/ADA-PFP/ADA-PFP-008.png';
import Adapfp009 from '../../images/ADA-PFP/ADA-PFP-009.png';
import Adapfp010 from '../../images/ADA-PFP/ADA-PFP-010.png';
import Adapfp011 from '../../images/ADA-PFP/ADA-PFP-011.png';
import Adapfp012 from '../../images/ADA-PFP/ADA-PFP-012.png';


const StyledCarousel2 = styled.div`
    * {
        /* border: 1px dotted orange; */
    }
`

export default function Carousel2() {


    return (
        <StyledCarousel2>
            <div class="slider">
                <div class="slide-track-container slide-track2">

                    {/* FIRST */}
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp006} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp007} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp008} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp009} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp010} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp011} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp012} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>

                    {/* SECOND */}
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp006} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp007} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp008} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp009} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp010} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp011} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp012} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>

                </div>
            </div>
        </StyledCarousel2>
    )
}