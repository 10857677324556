import React from "react";
import styled from "styled-components";
import twitter from '../../../images/twitter.png';

import PFPADuBz from "../../../images/ADuBz.png";


const StyledDiv = styled.div`

    max-width: 100%;
    max-height: 100%;
    
    display: flex;
    align-items: center;
    flex-direction: column;

    .card-container{
        max-width: 100%;
        max-height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        width: 100%;
        height: 100%;
        margin: 6% 7% 5% 0;
        /* padding: 2% 1%; */
        border-radius: 10px;
        
        .intro-container{
    
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: lightcoral;
            /* max-height: 100%; */
            width: 80%;
            /* height: 80%; */
            padding: 5%;
            color: white;
            background-color: #101010;
            border-radius: 10px;
            font-size: 1rem;
            filter: drop-shadow(0 0.3rem 0.3rem black);
    
    
            
            h3{
                font-family: 'New Rocker', cursive;
                padding: 0;
                letter-spacing: 3px;
                font-size: 1.9rem;
                text-align: center;
                font-weight: bold;
                background-image: linear-gradient(
                    to right,
                    #462523 0,
                    #cb9b51 2%, 
                    #f6e27a 45%,
                    #f6f2c0 50%,
                    #f6e27a 55%,
                    #cb9b51 98%,
                    #462523 100%
                );
                color:transparent;
                -webkit-background-clip: text;
                background-clip: text;
                margin: 5% 1% 2% 1%;
                /* text-shadow: 0 1px 30px violet; */
                -webkit-text-stroke-width: 0.1px;
                -webkit-text-stroke-color: #1a1a00;
            }

            img{
                width: 100%;
                height: auto;
                margin: 10% 0;
                border-radius: 10px;
                filter: drop-shadow(0 0.3rem 0.3rem black);

            }
            p{
                margin: 5% 0;
            }
        }
    }
    @media (min-width: 400px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1rem;
                .badge{
                    
                    h3{
                        font-size: 1.9rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 10% 0;
    
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 750px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1rem;
                .badge{
                    width: 80%;
                    h3{
                        font-size: 1.9rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 10% 0;
    
                    }
                    a{
                        img{
                            width: 40%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1000px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1.1rem;
                .badge{
                    width: 85%;
                    h3{
                        font-size: 2.3rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 10% 0;
    
                    }
                    a{
                        img{
                            width: 40%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1200px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1.1rem;
                .badge{
                    width: 90%;
                    h3{
                        font-size: 3rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 5% 0;
    
                    }
                    a{
                        img{
                            width: 30%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1800px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1.1rem;
                .badge{
                    width: 75%;
                    h3{
                        font-size: 3rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 5% 0;
    
                    }
                    a{
                        img{
                            width: 30%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
`

export default function ADuBz() {
    return (
        <StyledDiv>
            <div class="card-container">
                <div class="intro-container">
                    <div class='badge'>
                        <h3>ADuBZ SigMA</h3>
                        <div>
                            <img src={PFPADuBz} alt="ADuBZ SigMA"/>
                        </div>
                        <a href="https://mobile.twitter.com/atwill17"><img src={twitter} alt="@atwill17"/></a>
                    </div>
                    <div>
                        <p>
                            Hello, I'm Antwain Williams from Baltimore. Been here in the Crypto industry for over 4yrs and CNFTs a little over a year. My passion is playing sports, my photography business, and my ministry. My second Language is American Sign Language. I've learned this in recent years and the deaf community is where I received my second name,  which is A(sign), W (sign , flicked backwards) aka AW(Adubz). I have a Sigma introverted personality. Hence the name ADuBZ SigMA.
                        </p>
                        <p>
                            I'm a Co Founder and a Project Manager of A.D.A. The qualifications that help me in this role are my 15+ years in the industrial/commercial electrical construction industry. In my career I've learned and developed the skills of detailed planning, organizing teams, schedules, budgeting systems, project coordination, measuring trends, quality inspections, quality control, troubleshooting, project fulfillment and execution. My goal is to help and deliver a quality NFT project to the Ancient Dragon Alliance community‼️🤟🏿 
                        </p>
                    </div>
                </div>
            </div>
        </StyledDiv>
    );
}