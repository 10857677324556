import React from "react";
import styled from 'styled-components';

import Carousel1 from "./Carousel1";
import Carousel2 from "./Carousel2";
import Carousel3 from "./Carousel3";

const StyledPFP1 = styled.div`
  
    * {
        /* border: 1px dotted orange; */
    }
    height: 100%;
    margin: 5% 0;
    .slider {
        max-height: 100%;
        height: 100%;
        width: 100%;
        
        position: relative;
        overflow: hidden;
        .slide-track-container {
            width: calc(100px * 20);
            /* height: 100%; */
            display: flex;
            /* animation: scroll 40s linear infinite; */
            justify-content: center;
            /* background-color: lightcyan; */
    
            .slide {
                max-height: 100%;
                min-height: 100%;
                /* width: 100%; */
                height: auto;
                display: grid;
                place-items: center;
                /* margin: .5% .7%; */
                transition:0.5s;
                .gold-bar-container{
                    width: 100%;
                    margin: 0 15%;
                    padding: 10% 10% 9% 10%;
                    /* height: auto; */
                    display: flex;
                    justify-content: center;
                    /* align-items: baseline; */
                    border-radius: 10px;
                    background-image: linear-gradient(
                        to right,
                        #462523 0,
                        #cb9b51 22%, 
                        #f6e27a 45%,
                        #f6f2c0 50%,
                        #f6e27a 55%,
                        #cb9b51 78%,
                        #462523 100%
                    );
                    filter: drop-shadow(0 0.2rem 0.1rem orange);
    
                    .pfp{
                        width: 100%;
                        height: auto;
                        border-radius: 10px;
                        filter: drop-shadow(0 0.2rem 0.3rem black);
    
                    }
                }
            }
            
        }
    }
    .slide-track1{
        animation: scroll 40s linear infinite;
    }
    .slide-track2{
        animation: scroll2 40s linear infinite;
    }
    /* .slider::before,
    .slider::after{
        position:absolute;
        content:'';
        height:100%;
        width:25%;
        z-index:2;pointer-events:none;
    } */
    /* .slider::before{
        left:0;
        top:0;
    }
    .slider::after{
        right:0;
        top:0;
        transform:rotateZ(180deg);
    } */


    @keyframes scroll {
        0% {
            transform: translateX(0px);
        }
        100% {
            transform: translateX(calc(-150px * 10));
        }
    }
    @keyframes scroll2 {
        0% {
            transform: translateX(calc(-50px * 20));
        }
        100% {
            transform: translateX(0px);
        }
    }

    @media (min-width: 400px) {
        .slider {
            
            .slide-track-container {
                width: calc(120px * 20);
    
                .slide {
                    margin: .3%;
    
                    .gold-bar-container{
                        width: 75%;
                        /* padding: 10% 10% 9% 10%; */
                        
    
                        .pfp{
    
                        }
                    }
                }
                
            }
        }

    }
    @media (min-width: 750px) {

        .slider {
            
            .slide-track-container {
    
                .slide {
    
                    .gold-bar-container{
                        
    
                        .pfp{
    
                        }
                    }
                }
                
            }
        }

    }
    @media (min-width: 1000px) {
        .slider {
            
            .slide-track-container {
                width: calc(140px * 20);
    
                .slide {
                    margin: .3%;
    
                    .gold-bar-container{
                        width: 80%;
                        padding: 7% 7% 6% 7%;
                        
    
                        .pfp{
    
                        }
                    }
                    &:hover{
                        transform:scale(1.1);
                        animation-play-state: paused;
                    }
                }
            }
        }

    }
    @media (min-width: 1200px) {
        .slider {
            
            .slide-track-container {
                width: calc(160px * 20);
    
                .slide {
                    margin: .3% 0;
    
                    .gold-bar-container{
                        width: 70%;
                        padding: 8% 8% 7% 8%;
                        
    
                        .pfp{
    
                        }
                    }
                }
                
            }
        }

    }
    @media (min-width: 1800px) {
        .slider {
            
            .slide-track-container {
                width: calc(180px * 20);
    
                .slide {
                    /* margin: .3% 0%; */
    
                    .gold-bar-container{
                        width: 75%;
                        padding: 7% 7% 6% 7%;
                        
    
                        .pfp{
    
                        }
                    }
                }
                
            }
        }

    }
    @media (min-width: 2500px) {
        .slider {
            
            .slide-track-container {
                width: calc(200px * 20);
    
                .slide {
                    /* margin: 0%; */
    
                    .gold-bar-container{
                        width: 75%;
                        padding: 7% 7% 6% 7%;
                        
    
                        .pfp{
    
                        }
                    }
                }
                
            }
        }

    }
`

export default function PFPCarouselTest2() {


    return (
        <StyledPFP1>
            <Carousel1/>
            <Carousel2/>
            <Carousel3/>
        </StyledPFP1>
    )
}