import React from "react";
import styled from "styled-components";
import twitter from '../../../images/twitter.png';

import PFPMarti from "../../../images/Marti.png";


const StyledDiv = styled.div`

    max-width: 100%;
    max-height: 100%;
    
    display: flex;
    align-items: center;
    flex-direction: column;

    .card-container{
        max-width: 100%;
        max-height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        width: 100%;
        height: 100%;
        margin: 6% 7% 5% 0;
        /* padding: 2% 1%; */
        border-radius: 10px;
        
        .intro-container{
    
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: lightcoral;
            /* max-height: 100%; */
            width: 80%;
            /* height: 80%; */
            padding: 5%;
            color: white;
            background-color: #101010;
            border-radius: 10px;
            font-size: 1rem;
            filter: drop-shadow(0 0.3rem 0.3rem black);
    
    
            
            h3{
                font-family: 'New Rocker', cursive;
                padding: 0;
                letter-spacing: 3px;
                font-size: 1.9rem;
                text-align: center;
                font-weight: bold;
                background-image: linear-gradient(
                    to right,
                    #462523 0,
                    #cb9b51 2%, 
                    #f6e27a 45%,
                    #f6f2c0 50%,
                    #f6e27a 55%,
                    #cb9b51 98%,
                    #462523 100%
                );
                color:transparent;
                -webkit-background-clip: text;
                background-clip: text;
                margin: 5% 1% 2% 1%;
                /* text-shadow: 0 1px 30px violet; */
                -webkit-text-stroke-width: 0.1px;
                -webkit-text-stroke-color: #1a1a00;
            }

            img{
                width: 100%;
                height: auto;
                margin: 10% 0;
                border-radius: 10px;
                filter: drop-shadow(0 0.3rem 0.3rem black);

            }
            p{
                margin: 5% 0;
            }
        }
    }
    @media (min-width: 400px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1rem;
                .badge{
                    
                    h3{
                        font-size: 1.9rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 10% 0;
    
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 750px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1rem;
                .badge{
                    width: 80%;
                    h3{
                        font-size: 1.9rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 10% 0;
    
                    }
                    a{
                        img{
                            width: 40%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1000px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1.1rem;
                .badge{
                    width: 85%;
                    h3{
                        font-size: 2.3rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 10% 0;
    
                    }
                    a{
                        img{
                            width: 40%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1200px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1.1rem;
                .badge{
                    width: 90%;
                    h3{
                        font-size: 3rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 5% 0;
    
                    }
                    a{
                        img{
                            width: 30%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1800px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1.1rem;
                .badge{
                    width: 75%;
                    h3{
                        font-size: 3rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 5% 0;
    
                    }
                    a{
                        img{
                            width: 30%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
`

export default function Marti() {
    return (
        <StyledDiv>
            <div class="card-container">
                <div class="intro-container">
                    <div class='badge'>
                        <h3>Marti Rubels</h3>
                        <div>
                            <img src={PFPMarti} alt="Marti Rubels"/>
                        </div>
                        <a href="https://mobile.twitter.com/RealMartiRubels"><img src={twitter} alt="@RealMartiRubels"/></a>
                    </div>
                    <div>
                        <p>
                            Hello there, Marti Rubels here.
                        </p>
                        <p>
                            I'm very happy to be on the core team for Ancient Dragon Alliance. I head up social media for the project and can't wait for the future we have in store. I got my start beating the 9 to 5 as a guitarist and managing member for the musical group Modern Day Escape. After a record deal, I was able to gain my dragon's share of experience in marketing through overseeing promotions for multiple artists, record labels and acts.
                        </p>
                        <p>
                            After departing the entertainment industry in 2012, I got my start in crypto first with bitcoin, then then moving onto EVM chains during the DeFi spring, and eventually arriving here in the Cardano space managing social media for both ADA, and Outer Space Society. 
                        </p>
                        <p>
                            I attended the University of Central Florida, and have backgrounds in Adobe and Python coding. Its wonderful to be able to utilize my creativity, love of story telling, & affinity for fantasy (ESPECIALLY DRAGONS) to a project such as this. I'm extremely happy to offer my time and talents to our community, and look forward to meeting you all in what we are creating together!
                        </p>
                    </div>
                </div>
            </div>
        </StyledDiv>
    );
}