import React from "react";
import styled from "styled-components";
import twitter from '../../../images/twitter.png';

import PFPLavJ from "../../../images/LavJ.png";

const StyledDiv = styled.div`

    max-width: 100%;
    max-height: 100%;
    
    display: flex;
    align-items: center;
    flex-direction: column;

    .card-container{
        max-width: 100%;
        max-height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        width: 100%;
        height: 100%;
        margin: 6% 7% 5% 0;
        /* padding: 2% 1%; */
        border-radius: 10px;
        
        .intro-container{
    
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: lightcoral;
            /* max-height: 100%; */
            width: 80%;
            /* height: 80%; */
            padding: 5%;
            color: white;
            background-color: #101010;
            border-radius: 10px;
            font-size: 1rem;
            filter: drop-shadow(0 0.3rem 0.3rem black);
    
    
            
            h3{
                font-family: 'New Rocker', cursive;
                padding: 0;
                letter-spacing: 3px;
                font-size: 1.9rem;
                text-align: center;
                font-weight: bold;
                background-image: linear-gradient(
                    to right,
                    #462523 0,
                    #cb9b51 2%, 
                    #f6e27a 45%,
                    #f6f2c0 50%,
                    #f6e27a 55%,
                    #cb9b51 98%,
                    #462523 100%
                );
                color:transparent;
                -webkit-background-clip: text;
                background-clip: text;
                margin: 5% 1% 2% 1%;
                /* text-shadow: 0 1px 30px violet; */
                -webkit-text-stroke-width: 0.1px;
                -webkit-text-stroke-color: #1a1a00;
            }

            img{
                width: 100%;
                height: auto;
                margin: 10% 0;
                border-radius: 10px;
                filter: drop-shadow(0 0.3rem 0.3rem black);

            }
            p{
                margin: 5% 0;
            }
        }
    }
    @media (min-width: 400px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1rem;
                .badge{
                    
                    h3{
                        font-size: 1.9rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 10% 0;
    
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 750px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1rem;
                .badge{
                    width: 80%;
                    h3{
                        font-size: 1.9rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 10% 0;
    
                    }
                    a{
                        img{
                            width: 40%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1000px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1.1rem;
                .badge{
                    width: 85%;
                    h3{
                        font-size: 2.3rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 10% 0;
    
                    }
                    a{
                        img{
                            width: 40%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1200px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1.1rem;
                .badge{
                    width: 90%;
                    h3{
                        font-size: 3rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 5% 0;
    
                    }
                    a{
                        img{
                            width: 30%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1800px) {
        .card-container{
            width: 100%;
            height: 100%;
            margin: 6% 7% 5% 0;
            
            
            .intro-container{
        
                width: 80%;
                font-size: 1.1rem;
                .badge{
                    width: 75%;
                    h3{
                        font-size: 3rem;
                        margin: 5% 1% 2% 1%;
                    }
    
                    img{
                        width: 100%;
                        margin: 5% 0;
    
                    }
                    a{
                        img{
                            width: 30%;
                            height: auto;
                            
                        }
                    }
                }
                .team-intro{

                    p{
                        margin: 5% 0;
                    }
                }
            }
        }
    }
`

export default function LavJ() {
    return (
        <StyledDiv>
            <div class="card-container">
                <div class="intro-container">
                    <div class='badge'>
                        <h3>Lavender Jones</h3>
                        <div>
                            <img src={PFPLavJ} alt="Lavender Jones"/>
                        </div>
                        <a href="https://mobile.twitter.com/lavenderjon3s"><img src={twitter} alt="@LavenderJon3s"/></a>
                    </div>
                    <div>
                        <p>
                            Hey all!  I go by $LavenderJones within the crypto world, but my real name is Josch Kenerson.  I’m 27 years old and  grew up in a small town in New Jersey, but have since made Colorado my home.  I am one of the Co-Founders of Ancient Dragon Alliance and thought I'd give you all an overview of who I am! 
                        </p>
                        <p>
                            I originally started in the Forex space with my brothers years ago, where we learned how to track trends within a market.  About 7 years ago I was thrusted into the crypto space, having bought your standard BTC, ETH, LTC, etc.  It wasn’t until a good friend of mine started talking about this guy Charles and how he’s going to change the game that I really started paying attention to Cardano.  I bought in at around $.02, stacked my bags in a few other cryptos, and was able to take some serious profit and move across the country to start a career in real estate! 
                        </p>
                        <p>
                            I’ve been in the NFT space for about 9 months now, and have found a home in a lot of these communities!  I fumbled forward a lot in the beginning, falling for rugpulls and not investing my time and ada as wisely as I should.  But even with that, my portfolio remains net-positive, and have gained invaluable experience that lead me to building this project with friends made in Cardano!  Besides Ancient Dragon Alliance, I am also a co-host for a cNFT podcast called “Collective Conversations” where we break down projects into consumable chunks and have tons of fun doing it!
                        </p>
                        <p>
                            Outside of the Crypto space, my passions are people and plants.  I have a certificate in Advanced Permaculture Design and went to school for Psychology at The University of Pittsburgh.  I’ve always been community-forward and systems-thinking; I think that’s why I find myself in the cNFT space and spending hundreds of hours building this projects with some of the best people I’ve had the pleasure of interacting with!  Thank you for taking the time to read this, it means a lot to me!  In the meantime, buckle up because you’re in for a wild ride 😎 (Also enjoy this picture of me with my gf's cat, as you can see he's pumped to be taking a picture with his dad) 
                        </p>
                    </div>
                </div>
            </div>
        </StyledDiv>
    );
}