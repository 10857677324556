import React from "react";
import styled from 'styled-components';
import pfp from '../../images/mystery.gif';

import Adapfp001 from '../../images/ADA-PFP/ADA-PFP-001.png';
import Adapfp002 from '../../images/ADA-PFP/ADA-PFP-002.png';
import Adapfp003 from '../../images/ADA-PFP/ADA-PFP-003.png';
import Adapfp004 from '../../images/ADA-PFP/ADA-PFP-004.png';
import Adapfp005 from '../../images/ADA-PFP/ADA-PFP-005.png';



const StyledCarousel1 = styled.div`
    * {
        /* border: 1px dotted orange; */
    }
`

export default function Carousel1() {


    return (
        <StyledCarousel1>
            <div class="slider">
                <div class="slide-track-container slide-track1">

                    {/* FIRST */}
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp001} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp002} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp003} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp004} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp005} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>

                    {/* SECOND */}
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp001} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp002} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp003} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp004} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp005} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>

                </div>
            </div>
            
        </StyledCarousel1>
    );
}