import React, {useState} from "react";
import styled from "styled-components";

import SwitchComponents from "../hooks/SwitchComponents";

import PFPOsiris from "../images/Osiris.png";
import PFPLavJ from "../images/LavJ.png";
import PFPADuBz from "../images/ADuBz.png";
import PFPBlayno from "../images/Blayno.png";
import PFPMarti from "../images/Marti.png";

import ADuBz from "./team/core/ADuBz";
import Blayno from "./team/core/Blayno";
import LavJ from "./team/core/LavJ";
import Marti from "./team/core/Marti";
import Osiris from "./team/core/Osiris";
import Starting from "./team/core/Starting";

const StyledTeam = styled.div`
    *{
        /* border: 2px dotted orange; */
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    
    .blazing-team {
        margin: 20% 5% 5% 5%;
        
        color: whitesmoke;
        letter-spacing: 3px;
        font-family: 'New Rocker', cursive;
        font-size: 2.2rem;
        text-align: center;
        line-height: 80px;
        outline: none;
        vertical-align: middle;
        text-shadow:
            0 3px 20px red,
            0 0 20px red,
            0 0 10px orange,
            4px -5px 6px yellow,
            -4px -10px 10px yellow,
            0 -10px 30px yellow;
        animation: 2s Blazing infinite alternate linear;
            
    }
    .team-gold-container{
        width: 90%;
        max-height: 100%;
        margin: 5% 5% 10% 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* background-color: lightcoral; */
        background-image: linear-gradient(
            to right,
            #462523 0,
            #cb9b51 22%, 
            #f6e27a 45%,
            #f6f2c0 50%,
            #f6e27a 55%,
            #cb9b51 78%,
            #462523 100%
        );
        
        border-radius: 10px;
        filter: drop-shadow(0 0.3rem 0.3rem orange);

        .thumbnail-container{
            max-width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            /* height: 20vh; */
            padding: 2% 2%;

            .pfp-team{
                display: flex;
                justify-content: center;
                align-items: center;
                /* width: 120px;
                height: 120px; */
                width: 15%;
                height: auto;
                margin: 2% 2% 0% 2%;
                img{
                    width: 100%;
                    height: auto;
                    /* border: 1px solid darkgoldenrod; */
                    filter: drop-shadow(0 0.3rem 0.3rem black);
                    border-radius: 10px;
                }
            }
        }
        .team-content-container{
            /* height: 40vh; */
            width: 100%;
            margin: 0 3.5%;
            -webkit-transition: opacity 400ms ease-in;
            -moz-transition: opacity 400ms ease-in;
            transition: opacity 400ms ease-in;

            .member-card{
                text-align: center;
            }
        }
    }
    .thumbnail-container > div{
        cursor:pointer;
    }
    @media (min-width: 400px) {
        .blazing-team {
                
        }
        .team-gold-container{

            .thumbnail-container{
                
                .pfp-team{
                    
                    img{
                        
                    }
                }
            }
            .team-content-container{


                .member-card{
                    
                }
            }
        }
    }
    @media (min-width: 750px) {
        .blazing-team {
            margin: 8% 5% 0% 5%;
            font-size: 4rem;

        }
        .team-gold-container{
            width: 60%;
            /* height: 60%; */
            flex-direction: row;
            margin: 1% 0% 10% 0%;
            .team-content-wrapper{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                /* width: 100%;
                height: 100%; */
                .thumbnail-container{
                    flex-direction: column;
                    flex-wrap: nowrap;
                    width: 20%;
                    height: 100%;
                    padding: 0%;
                    margin: 3% 2% 1% 0% ;
                    
    
                    .pfp-team{
                        margin: 11% 0%;
                        width: 38%;
                        img{
                            width: 160%;
                        }
                    }
                }
                .team-content-container{
                    width: 80%;
                    /* height: 60%; */
                    /* position: relative; */
                    z-index: 3;
                    /* margin: 5% 0% 4% 0%; */
    
                    .member-card{
                        
                    }
                }
            }
        }
    }
    @media (min-width: 1000px) {
        .blazing-team {
            margin: 8% 5% 5% 5%;
            font-size: 4rem;

        }
        .team-gold-container{
            width: 60%;
            
            margin: 1% 0% 10% 0%;
            .team-content-wrapper{
                
                .thumbnail-container{
                    
                    margin: 3% 2% 1% 0% ;
                    
    
                    .pfp-team{
                        margin: 9% 0%;
                        width: 35%;
                        img{
                        }
                    }
                }
                .team-content-container{
    
                    .member-card{
                        
                    }
                }
            }
        }
    }
    @media (min-width: 1200px) {
        .blazing-team {
            margin: 5% 5% 2% 5%;
            font-size: 4rem;

        }
        .team-gold-container{
            width: 50%;
            
            margin: 1% 0% 10% 0%;
            .team-content-wrapper{
                
                .thumbnail-container{
                    
                    margin: 3% 2% 1% 0% ;
                    
    
                    .pfp-team{
                        margin: 9% 0%;
                        width: 35%;
                        img{
                        }
                    }
                }
                .team-content-container{
    
                    .member-card{
                        
                    }
                }
            }
        }
    }
    @media (min-width: 1800px) {
        .blazing-team {
            margin: 5% 5% 1% 5%;
            font-size: 4rem;

        }
        .team-gold-container{
            width: 45%;
            
            margin: 1% 0% 10% 0%;
            .team-content-wrapper{
                
                .thumbnail-container{
                    
                    margin: 3% 2% 1% 0% ;
                    
    
                    .pfp-team{
                        margin: 9% 0%;
                        width: 35%;
                        img{
                        }
                    }
                }
                .team-content-container{
    
                    .member-card{
                        
                    }
                }
            }
        }
    }
    @media (min-width: 2500px) {
        .blazing-team {
            margin: 5% 5% 1% 5%;
            font-size: 4rem;

        }
        .team-gold-container{
            width: 30%;
            
            margin: 1% 0% 10% 0%;
            .team-content-wrapper{
                
                .thumbnail-container{
                    
                    margin: 3% 2% 1% 0% ;
                    
    
                    .pfp-team{
                        margin: 9% 0%;
                        width: 35%;
                        img{
                        }
                    }
                }
                .team-content-container{
    
                    .member-card{
                        
                    }
                }
            }
        }
    }
`

export default function AboutTeam() {

    const [activeComponent, setActiveComponent] = useState("Start")

    return (
        <StyledTeam id="the-team">
            <h2 class='blazing-team'>Meet the Team!</h2>
            <div class='team-gold-container'>
                <div class='team-content-wrapper'>
                    
                    <div class='thumbnail-container'>
                        <div class='pfp-team' type='button' onClick={() => setActiveComponent('Osiris')}>
                            <img src={PFPOsiris} alt="OsirisGreen"/>
                        </div>
                        <div class='pfp-team' type='button' onClick={() => setActiveComponent('LavJ')}>
                            <img src={PFPLavJ} alt="Lavender Jones"/>
                        </div>
                        <div class='pfp-team' type='button' onClick={() => setActiveComponent('ADuBz')}>
                            <img src={PFPADuBz} alt="ADuBZ SigMA"/>
                        </div>
                        <div class='pfp-team' type='button' onClick={() => setActiveComponent('Blayno')}>
                            <img src={PFPBlayno} alt="Blayno"/>
                        </div>
                        <div class='pfp-team' type='button' onClick={() => setActiveComponent('Marti')}>
                            <img src={PFPMarti} alt="Marti Rubels"/>
                        </div>
                    </div>

                    <div class='team-content-container'>
                        <div class='member-card'>
                            <SwitchComponents active={activeComponent}>
                                <Starting name="Start" />
                                <Osiris name="Osiris" />
                                <LavJ name="LavJ" />
                                <ADuBz name="ADuBz" />
                                <Blayno name="Blayno" />
                                <Marti name="Marti" />
                            </SwitchComponents>

                        </div>
                    </div>
                </div>

            </div>

            {/* <div class='gold-line' /> */}
        </StyledTeam>
    )
}