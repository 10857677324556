import React from "react";
import styled from "styled-components";

import twitterFoot from "../images/foot-twitter.png";
import discordFoot from "../images/foot-discord.png";
import jpgstoreFoot from "../images/foot-jpeg.png";
import youtubeFoot from "../images/foot-youtube.png";


const StyledDiv = styled.div`
    max-width: 100%;
    max-height: 100%;
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    bottom: 0;
    -webkit-tap-highlight-color: transparent;

    .footer-container{
        display: flex;
        max-width: 100%;
        width: 100%;

        .footer-links {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            /* position: absolute; */
            /* top: 50%;
            left:50%; */
            /* transform: translate(-50%, -50%); */
            .footer-link-container {
                margin: 0 5%;
                width: 13%;
                height: auto;
                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    background: #171515;
                    border-radius: 50%;
                    color: #666;
                    transition: .5s;
                    text-decoration: none;
                    
                    img{
                        width: 100%;
                        height: auto;
                    
                    }
                }
                a:before {
                    /* content: ''; */
                    /* position: absolute;
                    top:0;
                    left:0; */
                    width:100%;
                    height:100%;
                    border-radius:50%;
                    background: #d35400;
                    transition: .5s;
                    transform: scale(.9);
                    z-index: -1;
                }
                a:hover:before {
                    transform: scale(1.2);
                    box-shadow: 0 0 15px #d35400;
                    filter: blur(3px);
                }
                a:hover {
                    /* background-color: #d35400;
                    color: #ffa502;
                    box-shadow: 0 0 15px #d35400;
                    text-shadow: 0 0 15px #d35400; */
                    background-color: #d35400;
                    color: #ffa502;
                    box-shadow: 0 0 15px darkgoldenrod;
                    text-shadow: 0 0 15px darkgoldenrod;
                    img{

                    }
                }
            }
        }
    }

    @media (min-width: 400px) {
        .container{

            ul{

                li{

                    a{

                        img{

                        }
                    }
                }
            }
        }
        
        ul li {
        }
    }

    @media (min-width: 750px) {
        .footer-container{

            .footer-links {
                margin: 0 0 5% 0;
                .footer-link-container {
                    width: 8%;
                    a {
                        
                        img{
                        
                        }
                    }
                    a:before {
                        
                    }
                    a:hover:before {
                        
                    }
                    a:hover {
                        
                        img{

                        }
                    }
                }
            }
        }
    }
    @media (min-width: 1000px) {
        .footer-container{

            .footer-links {
                margin: 0 0 2% 0;

                .footer-link-container {
                    width: 6%;

                    a {
                        
                        img{
                        
                        }
                    }
                    a:before {
                        
                    }
                    a:hover:before {
                        
                    }
                    a:hover {
                        
                        img{

                        }
                    }
                }
            }
        }
    }
    @media (min-width: 1200px) {
        .footer-container{

            .footer-links {
                
                .footer-link-container {
                    width: 5%;

                    a {
                        
                        img{
                        
                        }
                    }
                    a:before {
                        
                    }
                    a:hover:before {
                        
                    }
                    a:hover {
                        
                        img{

                        }
                    }
                }
            }
        }
    }
    @media (min-width: 1800px) {
        .footer-container{

            .footer-links {
                
                .footer-link-container {
                    width: 4%;

                    a {
                        
                        img{
                        
                        }
                    }
                    a:before {
                        
                    }
                    a:hover:before {
                        
                    }
                    a:hover {
                        
                        img{

                        }
                    }
                }
            }
        }
    }
    @media (min-width: 2500px) {
        .footer-container{

            .footer-links {
                margin: 0 10%;
                .footer-link-container {
                    
                    margin: 0 5%;
                    width: 3%;

                    a {
                        
                        img{
                        
                        }
                    }
                    a:before {
                        
                    }
                    a:hover:before {
                        
                    }
                    a:hover {
                        
                        img{

                        }
                    }
                }
            }
        }
    }
`

export default function Footer() {
return (
    <StyledDiv>
        <div id='socials' class="footer-container">
            <div class='footer-links'>
                <div class='footer-link-container'>
                    <a href="https://www.twitter.com/adragonalliance" target="_blank" rel='noreferrer'>
                        <img src={twitterFoot} alt='Twitter' />
                    </a>
                </div>
                <div class='footer-link-container'>
                    <a href="https://discord.gg/BjYDbAcc5P" target="_blank" rel='noreferrer'>
                        <img src={discordFoot} alt='Discord' />
                    </a>
                </div>
                <div class='footer-link-container'>
                    <a href="https://www.youtube.com/channel/UCiuAzmoXr5LL9eZy2HgS33g" target="_blank" rel='noreferrer'>
                        <img src={youtubeFoot} alt='JPEG Store' />
                    </a>
                </div>
                <div class='footer-link-container'>
                    <a href="https://www.jpg.store/collection/464fa27a93abe7e85f66797c1cf827bd69351c184d713f506305b370?tab=items" target="_blank" rel='noreferrer'>
                        <img src={jpgstoreFoot} alt='JPEG Store' />
                    </a>
                </div>
            </div>




            {/* <ul>
                <li>
                    <a href="https://www.twitter.com/adragonalliance">
                        <i>
                        </i>
                            <img src={twitterFoot} alt='Twitter' />
                    </a>
                </li>
                <li>
                    <a href="https://discord.gg/BjYDbAcc5P">
                        <i>
                        </i>
                        <img src={discordFoot} alt='Discord' />
                    </a>
                </li>
                <li>
                    <a href="https://www.youtube.com/channel/UCiuAzmoXr5LL9eZy2HgS33g">
                        <i>
                        </i>
                        <img src={youtubeFoot} alt='JPEG Store' />
                    </a>
                </li>
                <li>
                    <a href="https://www.jpg.store/collection/464fa27a93abe7e85f66797c1cf827bd69351c184d713f506305b370?tab=items">
                        <i>
                        </i>
                        <img src={jpgstoreFoot} alt='JPEG Store' />
                    </a>
                </li>
            </ul> */}
        </div>
    </StyledDiv>
);
}