import React from "react";
import styled from 'styled-components';
import pfp from '../images/mystery.gif';

import Adapfp001 from '../images/ADA-PFP/ADA-PFP-001.png';
import Adapfp002 from '../images/ADA-PFP/ADA-PFP-002.png';
import Adapfp003 from '../images/ADA-PFP/ADA-PFP-003.png';

import Adapfp004 from '../images/ADA-PFP/ADA-PFP-004.png';
import Adapfp005 from '../images/ADA-PFP/ADA-PFP-005.png';
import Adapfp006 from '../images/ADA-PFP/ADA-PFP-006.png';

import Adapfp007 from '../images/ADA-PFP/ADA-PFP-007.png';
import Adapfp008 from '../images/ADA-PFP/ADA-PFP-008.png';
import Adapfp009 from '../images/ADA-PFP/ADA-PFP-009.png';

const StyledPFP1 = styled.div`
  
    * {
        /* border: 1px dotted orange; */
    }
    height: 25vh;
    .slider {
        max-height: 100%;
        height: 100%;
        /* position: relative; */
        width: 100%;

        overflow: hidden;
        /* padding: 1% 0; */
        .slide-track {
            width: calc(100px * 20);
            /* height: 100%; */
            display: flex;
            animation: scroll 40s linear infinite;
            justify-content: center;
            /* background-color: lightcyan; */
    
            .slide {
                max-height: 100%;
                min-height: 100%;
                /* width: 100%; */
                height: auto;
                display: grid;
                place-items: center;
                margin: 0 .7%;
                transition:0.5s;
                /* background-color: lightcoral; */
                .gold-bar-container{
                    width: 100%;
                    margin: 0 15%;
                    padding: 10% 10% 9% 10%;
                    /* height: auto; */
                    display: flex;
                    justify-content: center;
                    /* align-items: baseline; */
                    border-radius: 10px;
                    background-image: linear-gradient(
                        to right,
                        #462523 0,
                        #cb9b51 22%, 
                        #f6e27a 45%,
                        #f6f2c0 50%,
                        #f6e27a 55%,
                        #cb9b51 78%,
                        #462523 100%
                    );
                    filter: drop-shadow(0 0.3rem 0.3rem orange);
    
                    .pfp{
                        width: 100%;
                        height: auto;
                        border-radius: 10px;
                        filter: drop-shadow(0 0.3rem 0.3rem black);
    
                    }
                }
            }
            /* .slide:hover{
                transform:scale(1.1)
            } */
        }
    }
    /* .slider::before,
    .slider::after{
        position:absolute;
        content:'';
        height:100%;
        width:25%;
        z-index:2;pointer-events:none;
    } */
    .slider::before{
        left:0;
        top:0;
    }
    .slider::after{
        right:0;
        top:0;
        transform:rotateZ(180deg);
    }

    /*  IMPORTANT CODE BELOW */


    @keyframes scroll {
        0% {
            transform: translateX(0px);
        }
        100% {
            transform: translateX(calc(-150px * 10));
        }
    }

    @media (min-width: 400px) {
        .slider {
            
            .slide-track {
                width: calc(170px * 20);
    
                .slide {
                    margin: 0 .3%;
    
                    .gold-bar-container{
                        width: 80%;
                        /* padding: 10% 10% 9% 10%; */
                        
    
                        .pfp{
    
                        }
                    }
                }
                
            }
        }

    }
    @media (min-width: 750px) {
        .slider {
            
            .slide-track {
                width: calc(170px * 20);
    
                .slide {
                    margin: 0 .2%;
    
                    .gold-bar-container{
                        width: 70%;
                        /* padding: 10% 10% 9% 10%; */
                        
    
                        .pfp{
    
                        }
                    }
                }
                
            }
        }

    }
    @media (min-width: 1000px) {
        .slider {
            
            .slide-track {
                width: calc(170px * 20);
    
                .slide {
                    margin: 0%;
    
                    .gold-bar-container{
                        width: 70%;
                        /* padding: 10% 10% 9% 10%; */
                        
    
                        .pfp{
    
                        }
                    }
                }
                
            }
        }

    }
    @media (min-width: 1200px) {
        .slider {
            
            .slide-track {
                width: calc(170px * 20);
    
                .slide {
                    margin: 0%;
    
                    .gold-bar-container{
                        width: 70%;
                        padding: 8% 8% 7% 8%;
                        
    
                        .pfp{
    
                        }
                    }
                }
                
            }
        }

    }
    @media (min-width: 1800px) {
        .slider {
            
            .slide-track {
                width: calc(250px * 20);
    
                .slide {
                    margin: 0%;
    
                    .gold-bar-container{
                        width: 75%;
                        padding: 7% 7% 6% 7%;
                        
    
                        .pfp{
    
                        }
                    }
                }
                
            }
        }

    }
    @media (min-width: 2500px) {
        .slider {
            
            .slide-track {
                width: calc(220px * 20);
    
                .slide {
                    margin: 0%;
    
                    .gold-bar-container{
                        width: 75%;
                        padding: 7% 7% 6% 7%;
                        
    
                        .pfp{
    
                        }
                    }
                }
                
            }
        }

    }
`

export default function PFPCarouselTest2() {


    return (
        <StyledPFP1>
            <div class="slider">
                <div class="slide-track">

                    {/* FIRST */}
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp001} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp002} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp003} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>

                    {/* SECOND */}
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp001} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp002} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={Adapfp003} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>
                    <div class="slide">
                        <div class='gold-bar-container'>
                            <img class='pfp' src={pfp} alt='Ancient Dragon Alliance' />
                        </div>
                    </div>

                </div>
            </div>
            
        </StyledPFP1>
    );
}